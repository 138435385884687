import { Multilingual } from 'interfaces/offer.interface';

export const DEFAULT_LOCALE = 'ES';

export function getTranslatedText(obj?: Multilingual, locale?: string): string {
  if (!obj) {
    return '';
  }

  const loc = locale?.substring(0, 2).toUpperCase() || '';

  // Lookup order:
  // - Given locale
  // - ES
  // - EN
  // - Default locale (which happens to be ES). Not useful now, but it does not hurts.
  return (
    obj[loc] ||
    obj[loc.toLowerCase()] ||
    obj['ES'] ||
    obj['es'] ||
    obj['EN'] ||
    obj['en'] ||
    obj[DEFAULT_LOCALE] ||
    obj[DEFAULT_LOCALE.toLowerCase()]
  );
}
