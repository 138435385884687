import { z } from 'zod';

export const ProfilePreferencesSchema = z.object({
  isPublic: z.boolean(),
  isIndexable: z.boolean(),
  slug: z.string(),
  defaultView: z.enum(['classic', 'timeline']),
  isEmailVisible: z.boolean(),
  isPhoneNumberVisible: z.boolean()
});

export type ProfilePreferences = z.infer<typeof ProfilePreferencesSchema>;
