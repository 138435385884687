import styled from 'styled-components';
import { screen, spacing } from 'theme';

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};
  margin-top: ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-top: ${spacing('md')};
  }
`;
