import {
  StyledSpinnerContainer,
  StyledSpinnerText,
  StyledSpinnerWrapper
} from './Spinner.styled';

type SpinnerProps = {
  text?: React.ReactNode;
};

const Spinner: React.FC<SpinnerProps> = ({ text }) => (
  <StyledSpinnerWrapper role="alert" aria-busy="true">
    <StyledSpinnerContainer>
      <div className="double-bounce1"></div>
      <div className="double-bounce2"></div>
    </StyledSpinnerContainer>

    {text && <StyledSpinnerText>{text}</StyledSpinnerText>}
  </StyledSpinnerWrapper>
);

export default Spinner;
