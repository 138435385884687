import Link from 'next/link';

type LinkTextProps = React.HTMLProps<HTMLAnchorElement> & {
  href?: string;
  target?: string;
};

export const LinkText: React.FC<LinkTextProps> = ({
  href,
  children,
  target,
  ...other
}) => {
  return (
    <Link href={href || '#'} passHref>
      <a target={target} {...other}>
        {children}
      </a>
    </Link>
  );
};
