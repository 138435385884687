const HOURS_SEPARATOR = ':';

export function getMinutesFromStringTime(time: string): number {
  try {
    const split = time.split(HOURS_SEPARATOR);

    return parseInt(split[0]) * 60 + parseInt(split[1]);
  } catch (error) {
    return 0;
  }
}

export function getStringTimeFromMinutes(num: number): string {
  const hours = num / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);

  const fMinutes =
    rMinutes < 10 ? rMinutes.toString().padStart(2, '0') : rMinutes.toString();

  const fHours =
    rHours < 10 ? rHours.toString().padStart(2, '0') : rHours.toString();

  return `${fHours}${HOURS_SEPARATOR}${fMinutes}`;
}
