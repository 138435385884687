import { ManfrediteOfferApplyRules } from 'model/manfredite-offer-apply-rules';

export type ManfrediteFieldsStatusReducerState = {
  fieldsStatus: ManfrediteOfferApplyRules;
  isLoading: boolean;
  isManfrediteSaved: boolean;
  userCouldApply: boolean;
};

export enum ManfrediteFieldsStatusReducerActionType {
  SET_FIELDS_STATUS = 'SET_FIELDS_STATUS',
  SET_MANFREDITE_AS_SAVED = 'SET_MANFREDITE_AS_SAVED',
  CALCULATE = 'CALCULATE'
}

export type ManfrediteFieldsStatusReducerAction =
  | {
      type: ManfrediteFieldsStatusReducerActionType.SET_MANFREDITE_AS_SAVED;
      payload: null;
    }
  | {
      type: ManfrediteFieldsStatusReducerActionType.SET_FIELDS_STATUS;
      payload: {
        fieldsStatus: ManfrediteOfferApplyRules;
      };
    }
  | {
      type: ManfrediteFieldsStatusReducerActionType.CALCULATE;
      payload: {
        fieldsStatus: ManfrediteOfferApplyRules;
      };
    };
