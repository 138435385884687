import { path, pipe } from 'ramda';
import { rem } from './helpers';

export const theme =
  (keys = []) =>
  (props) =>
    path(['theme', ...keys], props);

export const color = (key) => theme(['color', key]);

export const radius = (key) => theme(['radius', key]);

export const spacing = (key) => pipe(theme(['spacing', key]), rem);

export const typography = (keys = []) => theme(['typography', ...keys]);

export const fontSize = (key) => pipe(typography(['size', key]), rem);

export const fontWeight = (key) => typography(['weight', key]);

export const fontFamily = (key) => typography(['family', key]);

export const container = (key) => pipe(theme(['container', key]), rem);

export const shadow = (key) => theme(['shadow', key]);

export const screen = (key) => pipe(theme(['screen', key]), rem);

export const zindex = (key) => theme(['zindex', key]);
