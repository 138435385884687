import Badge from 'components/shared/Badge.styled';
import { TrimmedOfferLocations } from 'components/TrimmedOfferLocations';
import { useI18n } from 'hooks/useI18n';
import { UIOfferData } from 'interfaces/ui/ui-offer-data';
import { withTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  StyledClosed,
  StyledOffer,
  StyledOfferBadges,
  StyledOfferDetailItem,
  StyledOfferDetailItemGroup,
  StyledOfferDetailItemLabel,
  StyledOfferDetailItemSeparator,
  StyledOfferDetailItemValue,
  StyledOfferDetails,
  StyledOfferImage,
  StyledOfferInfo,
  StyledOfferLogo,
  StyledOfferTitle
} from './OfferApplyModalPositionCard.styled';

type OfferApplyModalPositionCard = {
  uiOffer: UIOfferData;
};

const OfferApplyModalPositionCard: React.FC<OfferApplyModalPositionCard> = ({
  uiOffer
}) => {
  const { locale } = useRouter();
  const { t } = useI18n('apply-modal');

  const salaryMin = uiOffer?.heroSectionData.salaryMin || null;
  const salaryMax = uiOffer?.heroSectionData.salaryMax || null;
  const remote = uiOffer?.heroSectionData.jobRemote;
  const equityInf = uiOffer?.bottombarData.equityInf;
  const equitySup = uiOffer?.bottombarData.equitySup;
  const companyColor = {
    '--client-primary': uiOffer?.heroSectionData.companyPrimaryColor
  } as React.CSSProperties;

  const hasLocations =
    uiOffer?.heroSectionData.jobLocationsSummary.trimmedCount > 0;

  return (
    <StyledOffer>
      <StyledOfferImage style={companyColor}>
        <img
          src={uiOffer.heroSectionData.companyPhoto}
          alt={uiOffer.heroSectionData.companyPhotoAlt}
        />
        {uiOffer.heroSectionData.closed && (
          <StyledClosed>
            {t('apply-modal.closed-offer', {
              date: new Intl.DateTimeFormat(locale, {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
              }).format(new Date(uiOffer.heroSectionData.closedDate || ''))
            })}
          </StyledClosed>
        )}
      </StyledOfferImage>

      <StyledOfferInfo>
        <StyledOfferLogo
          src={uiOffer.heroSectionData.companyLogo}
          alt={uiOffer.heroSectionData.companyName}
        />
        <StyledOfferTitle>{uiOffer.heroSectionData.jobTitle}</StyledOfferTitle>
        <StyledOfferDetails>
          {/* SALARIO */}
          <StyledOfferDetailItem>
            <StyledOfferDetailItemLabel>
              {t('apply-modal.salary')}
            </StyledOfferDetailItemLabel>
            <StyledOfferDetailItemValue>
              {!salaryMin && salaryMax && (
                <>
                  <span>{t('apply-modal.up_to')} </span>
                </>
              )}

              {salaryMin && !salaryMax && (
                <>
                  <span>{t('apply-modal.from')} </span>
                </>
              )}

              <span>{uiOffer.heroSectionData.currency}</span>

              {salaryMin && salaryMax && salaryMin !== salaryMax && (
                <>
                  <strong>{salaryMin}</strong>-<strong>{salaryMax}</strong>
                </>
              )}

              {salaryMin && salaryMax && salaryMin === salaryMax && (
                <>
                  <strong>{salaryMax}</strong>
                </>
              )}

              {!salaryMin && salaryMax && <strong>{salaryMax}</strong>}

              {salaryMin && !salaryMax && <strong>{salaryMin}</strong>}

              <span>K</span>
            </StyledOfferDetailItemValue>
          </StyledOfferDetailItem>
          {/* /SALARIO */}
          {/* BONUS */}
          {uiOffer.heroSectionData.bonus && (
            <StyledOfferDetailItem>
              <StyledOfferDetailItemLabel>
                {t('apply-modal.bonus')}
              </StyledOfferDetailItemLabel>
              <StyledOfferDetailItemValue>
                <span>{uiOffer.heroSectionData.currency}</span>
                {uiOffer.heroSectionData.bonus}
                <span>K</span>
              </StyledOfferDetailItemValue>
            </StyledOfferDetailItem>
          )}
          {/* /BONUS */}
          {/* EQUITY */}
          {uiOffer.bottombarData.hasEquity && (
            <StyledOfferDetailItem>
              <StyledOfferDetailItemLabel>
                {t('apply-modal.equity')}
              </StyledOfferDetailItemLabel>
              <StyledOfferDetailItemValue>
                {!equityInf && equitySup && (
                  <span className="extra">{t('apply-modal.up_to')} </span>
                )}
                {equityInf && !equitySup && (
                  <span className="extra">{t('apply-modal.from')} </span>
                )}
                {equityInf && equitySup && equityInf !== equitySup && (
                  <>
                    <strong>{equityInf}</strong>
                    <span>%</span>
                    <span> - </span>
                    <strong>{equitySup}</strong>
                    <span>%</span>
                  </>
                )}
                {equityInf && equitySup && equityInf === equitySup && (
                  <>
                    <strong>{equitySup}</strong>
                    <span>%</span>
                  </>
                )}
                {!equityInf && equitySup && (
                  <>
                    <strong>{equitySup}</strong>
                    <span>%</span>
                  </>
                )}
                {equityInf && !equitySup && (
                  <>
                    <strong>{equityInf}</strong>
                    <span>%</span>
                  </>
                )}
              </StyledOfferDetailItemValue>
            </StyledOfferDetailItem>
          )}
          {/* /EQUITY */}
          {/* LOCATION */}
          <StyledOfferDetailItemGroup>
            {hasLocations && (
              <StyledOfferDetailItem>
                <StyledOfferDetailItemLabel>
                  {t('apply-modal.presential')}
                </StyledOfferDetailItemLabel>
                <StyledOfferDetailItemValue>
                  {uiOffer && (
                    <TrimmedOfferLocations
                      locationsSummary={
                        uiOffer.heroSectionData.jobLocationsSummary
                      }
                    />
                  )}
                </StyledOfferDetailItemValue>
              </StyledOfferDetailItem>
            )}

            {hasLocations &&
              typeof remote !== 'undefined' &&
              remote < 100 &&
              remote > 0 && (
                <StyledOfferDetailItemSeparator>
                  {' '}
                  {t('apply-modal.and')}{' '}
                </StyledOfferDetailItemSeparator>
              )}

            {hasLocations && remote === 100 && (
              <StyledOfferDetailItemSeparator>
                {' '}
                {t('apply-modal.or')}{' '}
              </StyledOfferDetailItemSeparator>
            )}

            {remote !== 0 && (
              <StyledOfferDetailItem>
                <StyledOfferDetailItemLabel>
                  {t('apply-modal.remote')}
                </StyledOfferDetailItemLabel>
                <StyledOfferDetailItemValue>
                  {remote}%
                </StyledOfferDetailItemValue>
              </StyledOfferDetailItem>
            )}
          </StyledOfferDetailItemGroup>
          {/* /LOCATION */}
        </StyledOfferDetails>

        {uiOffer.heroSectionData.badges && (
          <StyledOfferBadges>
            {uiOffer.heroSectionData.badges.map((b) => (
              <Badge
                key={b.id}
                sizes="xs"
                variant={`${b.highlight ? 'primary' : 'secondary'}`}
              >
                {b.item}
              </Badge>
            ))}
          </StyledOfferBadges>
        )}
      </StyledOfferInfo>
    </StyledOffer>
  );
};

export default withTranslation(['apply-modal'])(OfferApplyModalPositionCard);
