import styled from 'styled-components';
import { color, screen, spacing } from '../../theme';

export const StyledSpinnerWrapper = styled.div`
  display: block;
`;

export const StyledSpinnerText = styled.div`
  text-align: center;
  position: absolute;
  top: 30px;
  left: 50%;
  margin: ${spacing('xl4')} auto;
  transform: translateX(-50%);

  @media (min-width: ${screen('md')}) {
    top: 275px;
  }
`;

export const StyledSpinnerContainer = styled.div<{
  variant?: string;
}>`
  position: absolute;
  top: 30px;
  left: 50%;
  margin: ${spacing('xl4')} auto;
  transform: translateX(-50%);
  z-index: 1;
  width: ${spacing('xl4')};
  height: ${spacing('xl4')};

  @media (min-width: ${screen('md')}) {
    top: 180px;
  }

  .double-bounce1,
  .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${color('emerald_500')};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;

    animation: sk-bounce 2s infinite ease-in-out;
  }

  .double-bounce2 {
    animation-delay: -1s;
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
`;
