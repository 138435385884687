/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  radius,
  spacing
} from '../../theme';
import Badge from './Badge.styled';

const roleVariants: {
  [key: string]: {
    backgroundColor: ((arg: any) => any) | string;
    color: ((arg: any) => any) | string;
    iconColor: ((arg: any) => any) | string;
    backgroundColorHover: ((arg: any) => any) | string;
    colorHover: ((arg: any) => any) | string;
    iconColorHover: ((arg: any) => any) | string;
    borderColor?: ((arg: any) => any) | string;
    borderColorHover?: ((arg: any) => any) | string;
    focus?: ((arg: any) => any) | string;
  };
} = {
  primary: {
    backgroundColor: color('midnight_800'),
    color: color('text_default_inverse'),
    iconColor: color('midnight_600'),
    backgroundColorHover: color('midnight_600'),
    colorHover: color('text_default_inverse'),
    iconColorHover: color('midnight_400'),
    borderColor: color('midnight_800'),
    borderColorHover: color('midnight_600'),
    focus: color('midnight_150')
  },
  secondary: {
    backgroundColor: 'transparent',
    color: color('text_default'),
    iconColor: color('text_default'),
    backgroundColorHover: 'transparent',
    colorHover: color('midnight_400'),
    iconColorHover: color('midnight_400'),
    borderColor: color('text_default'),
    borderColorHover: color('midnight_400'),
    focus: color('midnight_150')
  },
  tertiary: {
    backgroundColor: 'transparent',
    color: color('text_default'),
    iconColor: color('midnight_300'),
    backgroundColorHover: color('midnight_150'),
    colorHover: color('text_default'),
    iconColorHover: color('midnight_400'),
    borderColor: 'transparent',
    borderColorHover: 'transparent',
    focus: color('midnight_150')
  },
  brandTalent: {
    backgroundColor: color('emerald_600'),
    color: color('white'),
    iconColor: color('emerald_300'),
    backgroundColorHover: color('emerald_400'),
    colorHover: color('white'),
    iconColorHover: color('emerald_200'),
    borderColor: color('emerald_600'),
    borderColorHover: color('emerald_400'),
    focus: color('midnight_150')
  },
  brandTalentSecondary: {
    backgroundColor: 'transparent',
    color: color('emerald_700'),
    iconColor: color('emerald_700'),
    backgroundColorHover: 'transparent',
    colorHover: color('emerald_500'),
    iconColorHover: color('emerald_500'),
    borderColor: color('emerald_700'),
    borderColorHover: color('emerald_500'),
    focus: color('midnight_150')
  },
  brandCompany: {
    backgroundColor: color('navy_700'),
    color: color('white'),
    iconColor: color('navy_400'),
    backgroundColorHover: color('navy_500'),
    colorHover: color('white'),
    iconColorHover: color('navy_200'),
    borderColor: color('navy_700'),
    borderColorHover: color('navy_500'),
    focus: color('midnight_150')
  },
  primaryInverse: {
    backgroundColor: color('midnight_200'),
    color: color('text_default'),
    iconColor: color('midnight_400'),
    backgroundColorHover: color('midnight_400'),
    colorHover: color('text_default'),
    iconColorHover: color('midnight_600'),
    borderColor: color('midnight_200'),
    borderColorHover: color('midnight_400'),
    focus: color('midnight_700')
  },
  secondaryInverse: {
    backgroundColor: 'transparent',
    color: color('text_default_inverse'),
    iconColor: color('text_default_inverse'),
    backgroundColorHover: 'transparent',
    colorHover: color('text_default_inverse'),
    iconColorHover: color('midnight_500'),
    borderColor: color('text_default_inverse'),
    borderColorHover: color('midnight_500'),
    focus: color('midnight_700')
  },
  tertiaryInverse: {
    backgroundColor: 'transparent',
    color: color('text_default_inverse'),
    iconColor: color('midnight_700'),
    backgroundColorHover: color('midnight_850'),
    colorHover: color('text_default_inverse'),
    iconColorHover: color('midnight_600'),
    borderColor: 'transparent',
    borderColorHover: 'transparent',
    focus: color('midnight_700')
  },
  tertiaryInverseLightIcon: {
    backgroundColor: 'transparent',
    color: color('text_default_inverse'),
    iconColor: color('midnight_500'),
    backgroundColorHover: color('midnight_850'),
    colorHover: color('text_default_inverse'),
    iconColorHover: color('midnight_600'),
    borderColor: 'transparent',
    borderColorHover: 'transparent',
    focus: color('midnight_700')
  },
  active: {
    backgroundColor: color('active'),
    color: color('text_over_active'),
    iconColor: color('text_over_active'),
    backgroundColorHover: color('active'),
    colorHover: color('text_over_active'),
    iconColorHover: color('text_over_active'),
    borderColor: color('active'),
    borderColorHover: 'active',
    focus: color('text_over_active')
  },
  negative: {
    backgroundColor: color('negative_600'),
    color: color('white'),
    iconColor: color('negative_200'),
    backgroundColorHover: color('negative_700'),
    colorHover: color('white'),
    iconColorHover: color('negative_400'),
    borderColor: color('negative_600'),
    borderColorHover: color('negative_700'),
    focus: color('negative_150')
  },
  tertiaryHighlight: {
    backgroundColor: color('midnight_200'),
    color: color('text_default'),
    iconColor: color('midnight_500'),
    backgroundColorHover: color('midnight_200'),
    colorHover: color('text_default'),
    iconColorHover: color('midnight_400'),
    borderColor: 'transparent',
    borderColorHover: 'transparent',
    focus: color('midnight_150')
  },
  tertiaryNegative: {
    backgroundColor: 'transparent',
    color: color('negative_700'),
    iconColor: color('negative_500'),
    backgroundColorHover: color('midnight_150'),
    colorHover: color('negative_700'),
    iconColorHover: color('negative_500'),
    borderColor: 'transparent',
    borderColorHover: 'transparent',
    focus: color('midnight_150')
  },
  tertiaryEmerald: {
    backgroundColor: 'transparent',
    color: color('link'),
    iconColor: color('emerald_500'),
    backgroundColorHover: color('midnight_150'),
    colorHover: color('link'),
    iconColorHover: color('emerald_500'),
    borderColor: 'transparent',
    borderColorHover: 'transparent',
    focus: color('midnight_150')
  }
};

const sizeVariants: {
  [key: string]: {
    padding?: string | ((key: any) => string);
    iconSize?: string | ((key: any) => string);
    fontSize?: string | ((key: any) => string);
  };
} = {
  xs2: {
    padding: spacing('xs2'),
    fontSize: fontSize('xs2')
  },
  xs: {
    padding: spacing('xs'),
    fontSize: fontSize('xs')
  },
  sm: {
    padding: spacing('sm'),
    fontSize: fontSize('xs')
  },
  lg: {
    padding: '1.25rem',
    iconSize: spacing('lg')
  },
  header: {
    fontSize: fontSize('xs')
  }
};

export const ButtonLink = styled.a<{
  variant?: string;
  sizes?: string;
  isIcon?: boolean;
  isLink?: boolean;
  isLinkDark?: boolean;
  hasCaret?: boolean;
  hasBadge?: boolean;
  normalCase?: boolean;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${color('midnight_800')};
  border-radius: ${radius('md')};
  background-color: ${color('midnight_800')};
  color: ${color('text_default_inverse')};
  font-family: ${fontFamily('sans')};
  font-size: ${fontSize('sm')};
  line-height: 1;
  text-transform: uppercase;
  font-weight: ${fontWeight('black')};
  vertical-align: text-bottom;
  padding: ${spacing('md')};
  transition: 0.3s;
  cursor: pointer;

  ${({ normalCase }) =>
    normalCase &&
    css`
      text-transform: none;
    `}

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
    margin: 0 ${spacing('xs')};
    color: ${color('midnight_600')};
    flex-shrink: 0;
    transition: 0.3s;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: ${color('midnight_600')};
    color: ${color('text_default_inverse')};
    border-color: ${color('midnight_600')};

    svg {
      color: ${color('midnight_400')};
    }
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 4px ${color('midnight_150')};
  }

  span {
    margin-top: 0.1em;
  }

  ${({ sizes }) =>
    sizes &&
    sizeVariants[sizes] &&
    css`
      padding: ${sizeVariants[sizes].padding};
      font-size: ${sizeVariants[sizes].fontSize};

      svg {
        width: ${sizeVariants[sizes].iconSize} !important;
        height: ${sizeVariants[sizes].iconSize};
      }
    `}

  ${({ isIcon }) =>
    isIcon &&
    css`
      svg {
        width: 2.25em !important;
        height: 2.25em;
        margin: -0.4em;
      }

      span {
        width: 1.45em;
      }
    `}
  
    ${({ isIcon, sizes }) =>
    isIcon &&
    sizes &&
    sizes === 'sm' &&
    css`
      width: 2.5rem;
      height: 2.5rem;
      svg {
        width: ${spacing('lg')} !important;
        height: ${spacing('lg')};
      }
    `}

    ${({ isIcon, sizes }) =>
    isIcon &&
    sizes &&
    sizes === 'xs' &&
    css`
      width: ${spacing('xl')};
      height: ${spacing('xl')};
      svg {
        width: ${spacing('md')} !important;
        height: ${spacing('md')};
      }
    `}
  
    ${({ isLink }) =>
    isLink &&
    css`
      color: ${color('link')};
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      border: 0;

      &:hover {
        color: ${color('link_hover')};
        background-color: transparent;
      }

      &:active,
      &:focus,
      &:focus-visible {
        color: ${color('link_active')};
        background-color: transparent;
        border: 0;
        box-shadow: unset;
        outline: 0;
      }
    `}

    ${({ isLinkDark }) =>
    isLinkDark &&
    css`
      color: ${color('link_inverse')};
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
      border: 0;

      &:hover {
        color: ${color('link_inverse_hover')};
        background-color: transparent;
      }

      &:active,
      &:focus,
      &:focus-visible {
        color: ${color('link_inverse_active')};
        background-color: transparent;
        border: 0;
        box-shadow: unset;
        outline: 0;
      }
    `}

    ${({ hasCaret }) =>
    hasCaret &&
    css`
      &::after {
        content: '';
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.538289 0.730988H7.46171C7.94067 0.730988 8.18015 1.30951 7.84111 1.64855L4.38075 5.11161C4.17087 5.32149 3.82913 5.32149 3.61925 5.11161L0.158886 1.64855C-0.180154 1.30951 0.0593268 0.730988 0.538289 0.730988Z' fill='%23353241'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: ${spacing('xs')};
        height: ${spacing('xs')};
        margin-left: ${spacing('sm')};
      }
    `}

    ${({ hasBadge }) =>
    hasBadge &&
    css`
      position: relative;

      ${Badge} {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
      }
    `}

    ${({ variant }) =>
    variant &&
    roleVariants[variant] &&
    css`
      background-color: ${roleVariants[variant].backgroundColor};
      color: ${roleVariants[variant].color};
      border-color: ${roleVariants[variant].borderColor};

      svg {
        color: ${roleVariants[variant].iconColor};
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        background-color: ${roleVariants[variant].backgroundColorHover};
        color: ${roleVariants[variant].colorHover} !important;
        border-color: ${roleVariants[variant].borderColorHover};

        svg {
          color: ${roleVariants[variant].iconColorHover};
        }
      }

      &:focus {
        box-shadow: 0 0 0 4px ${roleVariants[variant].focus};
      }
    `}
`;

export default ButtonLink;
