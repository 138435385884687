// Tipos
// -----------------------------------------------------------------------------
type FetchErrorConstructor = {
  statusText: string;
  statusCode: number;
};

// Clase.
// -----------------------------------------------------------------------------
export class FetchError extends Error {
  // Atributos.
  // ---------------------------------------------------------------------------
  statusCode: number;

  // Constructor.
  // ---------------------------------------------------------------------------
  constructor({ statusCode, statusText }: FetchErrorConstructor) {
    super(statusText);

    this.statusCode = statusCode;
  }
}
