import Button from 'components/shared/Button.styled';
import HtmlTrans from 'components/shared/HtmlTrans';
import { useI18n } from 'hooks/useI18n';
import { withTranslation } from 'next-i18next';
import {
  StyledActions,
  StyledContainer,
  StyledText
} from './OfferApplyModalPositionNonExistent.styled';

type OfferApplyModalPositionNonExistentProps = {
  applyModalClose: () => void;
  //offerId: number;
  handleNoClick: () => void;
};

const OfferApplyModalPositionNonExistent: React.FC<
  OfferApplyModalPositionNonExistentProps
> = ({ applyModalClose, handleNoClick }) => {
  const { t } = useI18n('apply-modal');

  return (
    <StyledContainer>
      <StyledText>
        <HtmlTrans
          ns="apply-modal"
          i18nKey="offer-not-exist.subtitle"
          components={{
            email: <a href="mailto:support@getmanfred.com" />
          }}
        />
      </StyledText>

      <StyledActions>
        <Button
          variant="primary"
          id="modal-go-button"
          aria-label={t('offer-not-exist.CTA-wait')}
          onClick={() => {
            applyModalClose();
          }}
        >
          <span aria-hidden="true">{t('offer-not-exist.CTA-wait')}</span>
        </Button>
        <Button
          variant="secondary"
          id="modal-go-button"
          aria-label={t('offer-not-exist.CTA-stop-application')}
          onClick={() => {
            applyModalClose();
            handleNoClick();
          }}
        >
          <span aria-hidden="true">
            {t('offer-not-exist.CTA-stop-application')}
          </span>
        </Button>
      </StyledActions>
    </StyledContainer>
  );
};

export default withTranslation(['apply-modal'])(
  OfferApplyModalPositionNonExistent
);
