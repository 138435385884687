import { z } from 'zod';

export const ListItemSchema = z.object({
  label: z.string(),
  value: z.number()
});

export const ListItemStringValueSchema = z.object({
  label: z.string(),
  value: z.string()
});

export type ListItemV2 = z.infer<typeof ListItemSchema>;
export type ListItemStringValueV2 = z.infer<typeof ListItemStringValueSchema>;
