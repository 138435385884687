import { useSessionStorage } from 'components/hooks/useSessionStorage';
import { ALREADY_SHOWN_MISSING_DATA_MODAL_SESSION_PREFIX } from 'components/shared/offerapplymodal.context.constants';
import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { ManfrediteFieldsStatusContext } from 'context/manfredite-fields-status';
import { ManfrediteApplicationAttempt } from 'interfaces/manfredite.interface';
import { ManfrediteRuleName } from 'model/manfredite-offer-apply-rules';
import { useContext, useEffect, useState } from 'react';

type UsePendingFieldsForOfferApplying = {
  filledCount: number;
  pendingCount: number;
  totalCount: number;
  toggleDropdown: () => void;
  applicationAttempt: ManfrediteApplicationAttempt | undefined;
  isDropdownOpened: boolean;
  pendingFieldsForOfferApplying: ManfrediteRuleName[];
  isManfrediteSaved: boolean;
  userCouldApplyToOffers: boolean;
};

export const usePendingFieldsForOfferApplying =
  (): UsePendingFieldsForOfferApplying => {
    const { fieldsStatus, isManfrediteSaved, userCouldApplyToOffers } =
      useContext(ManfrediteFieldsStatusContext);

    const { user } = useContext(LoggedUserContext);

    const SESSION_STORAGE_KEY = `${ALREADY_SHOWN_MISSING_DATA_MODAL_SESSION_PREFIX}${
      user?.offers?.attempt?.offerId || ''
    }`;

    const [alreadyShowInSessionMissingDataModal] = useSessionStorage(
      SESSION_STORAGE_KEY,
      false
    );

    const applicationAttempt = user?.offers?.attempt;

    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const fulfilledFieldsForOfferApplying =
      fieldsStatus?.fulfilledFieldsForOfferApplying || [];

    const pendingFieldsForOfferApplying =
      fieldsStatus?.pendingFieldsForOfferApplying || [];

    const filledCount = fulfilledFieldsForOfferApplying.length;
    const pendingCount = pendingFieldsForOfferApplying.length;
    const totalCount = filledCount + pendingCount;

    function toggleDropdown(): void {
      setIsDropdownOpened((prevValue) => !prevValue);
    }

    useEffect(() => {
      const hasAttempt = !!applicationAttempt?.offerId;

      // * The delay is needed to give some time for the DOM node to be ready
      // * so the height of the dropdown can be calculated
      // * I know it's weird, but it seems to work like that… ¯\_(ツ)_/¯
      setTimeout(() => {
        setIsDropdownOpened(
          hasAttempt && !alreadyShowInSessionMissingDataModal
        );
      }, 100);
    }, [applicationAttempt?.offerId, alreadyShowInSessionMissingDataModal]);

    return {
      filledCount,
      pendingCount,
      totalCount,
      toggleDropdown,
      applicationAttempt,
      isDropdownOpened,
      pendingFieldsForOfferApplying,
      isManfrediteSaved,
      userCouldApplyToOffers
    };
  };
