import styled, { css } from 'styled-components';
import {
  color,
  container,
  fontSize,
  fontWeight,
  radius,
  screen,
  shadow,
  spacing
} from '../../theme';
import { ModalSize } from './Modal.types';

const sizeVariants: Record<
  ModalSize,
  {
    width?: string | ((key: any) => string);
  }
> = {
  sm: {
    width: container('xs')
  },
  md: {
    width: container('sm')
  },
  lg: {
    width: container('md')
  }
};

export const StyledModal = styled.div<{
  size?: ModalSize;
}>`
  overflow: auto;
  background-color: ${color('white')};
  border-radius: ${radius('md')};
  box-shadow: ${shadow('xl_dark')};
  max-width: 90vw;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  width: ${sizeVariants.md.width};

  ${({ size }) =>
    size &&
    sizeVariants[size] &&
    css`
      width: ${sizeVariants[size].width};
    `}
`;

export const StyledModalContainer = styled.div`
  padding: ${spacing('xl')};
  flex-grow: 1;

  @media (max-width: ${screen('mdMax')}) {
    padding: 1.25rem;
  }
`;

export const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing('lg')};
`;

export const StyledModalTitle = styled.h3`
  font-size: ${fontSize('xl')};
  line-height: 1.16;
  font-weight: ${fontWeight('black')};
  margin: 0;

  @media (max-width: ${screen('mdMax')}) {
    font-size: ${fontSize('lg')};
    line-height: 1.2;
  }
`;

export const StyledModalClose = styled.button`
  margin-left: ${spacing('xl')};
  margin-top: 0.3em;
  background: transparent;
  border: 0;
  padding: 0;
  color: ${color('midnight_300')};
  cursor: pointer;

  svg {
    width: ${spacing('xl')} !important;
    height: ${spacing('xl')} !important;
  }
`;

export const StyledModalSidebar = styled.div`
  background-color: ${color('emerald_100')};
  padding: ${spacing('lg')};

  max-width: 100%;

  @media (min-width: ${screen('lg')}) {
    width: 20rem;
  }
`;

export const StyledOverlayBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledModalActions = styled.div<{ fill?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing('md')};
  margin-top: ${spacing('md')};

  button {
    ${({ fill }) =>
      !!fill &&
      css`
        flex-grow: 1;
        max-width: 50%;
      `}
  }

  @media (max-width: ${screen('md')}) {
    display: block;

    button {
      display: block;
      width: 100%;
      max-width: unset;
      margin-bottom: ${spacing('md')};
    }
  }
`;
