import {
  faLinkedin,
  faStackOverflow
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  NotificationVariant,
  StyledNotification,
  StyledNotificationAction,
  StyledNotificationContent,
  StyledNotificationIcon,
  StyledNotificationTitle
} from './Notification.styled';

export type NotificationProps = {
  variant: NotificationVariant;
  title?: string;
  onClick?: () => void;
};

const Notification: React.FC<NotificationProps> = ({
  variant,
  children,
  title,
  onClick
}) => {
  return (
    <StyledNotification variant={variant}>
      <StyledNotificationIcon>
        {variant === 'loading' && (
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        )}
        {variant === 'info' && <FontAwesomeIcon icon={faInfoCircle} />}
        {variant === 'notice' && (
          <FontAwesomeIcon icon={faExclamationTriangle} />
        )}
        {variant === 'positive' && <FontAwesomeIcon icon={faCheckCircle} />}
        {variant === 'negative' && <FontAwesomeIcon icon={faTimesCircle} />}
        {variant === 'stackOverflow' && (
          <FontAwesomeIcon icon={faStackOverflow} />
        )}
        {variant === 'linkedIn' && <FontAwesomeIcon icon={faLinkedin} />}
      </StyledNotificationIcon>
      <StyledNotificationContent>
        {title && <StyledNotificationTitle>{title}</StyledNotificationTitle>}
        <div>{children}</div>
      </StyledNotificationContent>
      {onClick && (
        <StyledNotificationAction
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </StyledNotificationAction>
      )}
    </StyledNotification>
  );
};

export default Notification;
