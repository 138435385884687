const container = {
  xs3: '192',
  xs2: '300',
  xs: '480',
  sm: '680',
  md: '736',
  lg: '992',
  xl: '1120',
  xlMax: '1400',
  profile: '800',
  portalSidebar: '256',
};

export default container;
