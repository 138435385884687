import { ManfrediteConfigurationPublicProfileViewsType } from '@asgard/common-dto';
import { GetServerSidePropsResult, GetStaticPropsResult } from 'next';
import appUrls from '../next.paths';
import { config } from './config';

export type AppUrlPath = {
  path: string;
  params: string;
  langs: {
    [key: string]: string;
  };
};
export type AppUrl = { [key: string]: AppUrlPath };
export type AppUrls = Record<keyof typeof appUrls, AppUrlPath>;

export const APP_URLS: Readonly<AppUrls> = appUrls;

export const getAppUrl = (url: AppUrlPath, locale = 'en'): string => {
  return url.langs[locale.toLowerCase()];
};

export const getShortAppBaseUrl = (): string => {
  return config.shortUrl || config.appUrl;
};

export const getNotFoundUrl = (locale = 'en'): string => {
  return getAppUrl(APP_URLS.notFound, locale);
};

export const getSSRRedirectionTo = <T>(
  url: string
): GetServerSidePropsResult<T> => {
  return {
    redirect: {
      destination: url,
      permanent: false
    }
  };
};

export const getSSGRedirectionTo = <T>(
  url: string,
  revalidate = 30
): GetStaticPropsResult<T> => {
  return {
    revalidate,
    redirect: {
      destination: url,
      permanent: false
    }
  };
};

export const getPublicProfileUrl = (opts: {
  slug: string;
  view?: ManfrediteConfigurationPublicProfileViewsType;
  locale?: string;
}): string => {
  const { slug, view, locale } = opts;

  const suffix = config.shortUrl
    ? `/${slug}`
    : `${locale ? '/' + locale : ''}${getAppUrl(
        APP_URLS.manfrediteProfileV2,
        locale
      )}/${slug}`;

  const baseUrl = config.shortUrl ? config.shortUrl : config.appUrl;

  return `${baseUrl}${suffix}${view ? '/' + view : ''}`;
};
