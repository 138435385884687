import * as envVars from 'next.env-vars';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

type ConfigValue = string;

const getRuntimeVariable = (
  runtimeVariable: string,
  defaultValue?: ConfigValue
): ConfigValue => {
  const unvalidatedRuntimeVariable = publicRuntimeConfig[runtimeVariable];

  if (!unvalidatedRuntimeVariable) {
    if (defaultValue != null) {
      return defaultValue;
    }

    return '';
  } else {
    return unvalidatedRuntimeVariable;
  }
};

const getAppConfig = (): Record<keyof typeof envVars, ConfigValue> => {
  const config: Record<string, ConfigValue> = {};

  Object.entries(envVars).forEach(([key, value]) => {
    config[key] = getRuntimeVariable(value.name, value.defaultValue);
  });

  return config;
};

export const config = getAppConfig();
