import {
  ManfrediteFieldsStatusReducerAction,
  ManfrediteFieldsStatusReducerActionType,
  ManfrediteFieldsStatusReducerState
} from './manfredite-fields-status.provider.types';

export default function ManfrediteFieldsStatusReducer(
  state: ManfrediteFieldsStatusReducerState,
  action: ManfrediteFieldsStatusReducerAction
): ManfrediteFieldsStatusReducerState {
  const { type, payload } = action;

  switch (type) {
    case ManfrediteFieldsStatusReducerActionType.SET_MANFREDITE_AS_SAVED:
      return {
        ...state,
        isManfrediteSaved: true,
        isLoading: false
      };

    case ManfrediteFieldsStatusReducerActionType.SET_FIELDS_STATUS:
      return {
        ...state,
        fieldsStatus: payload.fieldsStatus,
        isLoading: false,
        userCouldApply:
          (payload.fieldsStatus.pendingFieldsForOfferApplying &&
            payload.fieldsStatus.pendingFieldsForOfferApplying?.length <= 0) ||
          false
      };

    case ManfrediteFieldsStatusReducerActionType.CALCULATE:
      return {
        ...state,
        fieldsStatus: payload.fieldsStatus,
        isManfrediteSaved: false,
        isLoading: false
      };

    default:
      return { ...state };
  }
}
