import TooltipMF from 'components/shared/TooltipMF';
import { TrimmedOfferLocationsProps } from './TrimmedOfferLocations.types';

export const TrimmedOfferLocations: React.FC<TrimmedOfferLocationsProps> = ({
  locationsSummary
}) => {
  return (
    <>
      {locationsSummary.trimmedCitiesStr}
      <>
        {locationsSummary.extraCount > 0 && (
          <TooltipMF
            content={locationsSummary.extraCitiesStr}
            direction="up"
            tagName="span"
          >
            {' '}
            (+{locationsSummary.extraCount})
          </TooltipMF>
        )}
      </>
    </>
  );
};
