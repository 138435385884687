import { ManfrediteFieldsStatusReducerState } from './manfredite-fields-status.provider.types';

export const DEBOUNCE_TIME_IN_MILLIS_FOR_CALCULATE_FIELDS = 250;

export const INITIAL_STATE: ManfrediteFieldsStatusReducerState = {
  fieldsStatus: {
    fulfilledFieldsForOfferApplying: [],
    pendingFieldsForOfferApplying: undefined
  },
  isLoading: true,
  isManfrediteSaved: true,
  userCouldApply: false
};
