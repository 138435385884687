import { BookmarkTypeValues } from '@asgard/api-common';
import { z } from 'zod';
import { MediaSummarySchema } from './model.media-summary';

export const BookmarkSchema = z.object({
  id: z.number(),
  type: z.enum(BookmarkTypeValues),
  title: z.string(),
  category: z.string().optional(),
  author: z.string().optional(),
  description: z.string().optional(),
  image: MediaSummarySchema.optional(),
  url: z.string().optional()
});

export type Bookmark = z.infer<typeof BookmarkSchema>;
