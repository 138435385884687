import classNames from 'classnames';
import { ReactNode } from 'react';
import Tooltip from 'react-tooltip-lite';

type Props = {
  content: ReactNode;
  eventOn?: string;
  eventOff?: string;
  eventToggle?: string;
  direction?: string;
  large?: string | boolean;
  isOpen?: boolean;
  distance?: number;
  type?: 'full-content' | 'text';
  tagName?: string;
};

const TooltipMF: React.FC<Props> = ({
  content,
  eventOn,
  eventOff,
  eventToggle,
  direction,
  large,
  isOpen,
  distance,
  children,
  type = 'text',
  tagName = 'div'
}) => {
  if (!content) {
    return <>{children}</>;
  }
  return (
    <Tooltip
      content={
        <div
          className={classNames({
            'tooltip--lg': large,
            'tooltip--md': !large,
            'type--text': type === 'text',
            'type--full-content': type === 'full-content'
          })}
        >
          {content}
        </div>
      }
      eventOn={eventOn ? eventOn : 'onFocus'}
      eventOff={eventOff ? eventOff : 'onBlur'}
      eventToggle={eventToggle}
      direction={direction}
      padding={12}
      arrowSize={6}
      tipContentHover
      isOpen={isOpen}
      distance={distance ? distance : 12}
      tagName={tagName}
    >
      {children ? children : <></>}
    </Tooltip>
  );
};

export default TooltipMF;
