import Cookies, { CookieGetOptions, CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

type CookiesAddOptions = CookieSetOptions;
type CookiesGetOptions = CookieGetOptions;

const CookiesService = {
  add: (name: string, value: unknown, opts?: CookiesAddOptions): void => {
    cookies.set(name, value, {
      path: '/',
      ...opts
    });
  },

  get: (name: string, opts?: CookiesGetOptions): any => {
    return cookies.get(name, opts);
  }
};

export const COOKIES_NEXT_LOCALE = 'NEXT_LOCALE';

export default CookiesService;
