const screen = {
  sm: '320',
  md: '672',
  lg: '1056',
  xl: '1312',
  xl2: '1548',
  smMax: '319',
  mdMax: '671',
  lgMax: '1055',
  xlMax: '1311',
  xl2Max: '1547',
};

export default screen;
