import { SalaryRange } from 'interfaces/manfredite.interface';
import { getTranslation } from 'utils/i18n';

export function getSalaryRangeText(
  { min, max, currency }: SalaryRange,
  minMax?: boolean,
  locale?: string
): string {
  if (min && !max) {
    return (
      getTranslation(minMax ? 'salary.min' : 'salary.from', 'common', locale, {
        min: getKSalary(min),
        currency
      }) || ''
    );
  }

  if (!min && max) {
    return getTranslation(
      minMax ? 'salary.max' : 'salary.up_to',
      'common',
      locale,
      {
        max: getKSalary(max),
        currency
      }
    );
  }

  if (min === max) {
    return getTranslation('salary.equal', 'common', locale, {
      min: getKSalary(min),
      max: getKSalary(max),
      currency
    });
  }

  return getTranslation('salary.minMax', 'common', locale, {
    min: getKSalary(min),
    max: getKSalary(max),
    currency
  });
}

/**
 * Get the salary in K format (without the letter K)
 *
 * @param salary - salary
 * @returns the salary converted to K format
 */
export function getKSalary(salary: number): number {
  return salary / 1000;
}
