import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import {
  StyledModal,
  StyledModalClose,
  StyledModalContainer,
  StyledModalHeader,
  StyledModalTitle,
  StyledOverlayBlock
} from './Modal.styled';
import { ModalProps } from './Modal.types';

const Modal: React.FC<ModalProps> = ({
  title,
  children,
  onClose,
  blockUI = false,
  size
}) => {
  const { t } = useI18n('common');

  return (
    <StyledModal size={size}>
      <StyledModalContainer>
        {blockUI && <StyledOverlayBlock></StyledOverlayBlock>}

        <StyledModalHeader>
          {title && <StyledModalTitle>{title}</StyledModalTitle>}
          {!title && <StyledModalTitle as="div" />}

          {onClose && (
            <StyledModalClose
              type="button"
              onClick={onClose}
              aria-label={t('close') || ''}
            >
              <FontAwesomeIcon icon={faTimes} />
            </StyledModalClose>
          )}
        </StyledModalHeader>

        {children}
      </StyledModalContainer>
    </StyledModal>
  );
};

export default Modal;
