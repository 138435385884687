import color from './color';
import container from './containers';
import radius from './radii';
import screen from './screens';
import shadow from './shadows';
import spacing from './spacing';
import typography from './typography';
import zindex from './zindexes';

const variables = {
  color,
  radius,
  spacing,
  typography,
  container,
  shadow,
  screen,
  zindex,
};
export default variables;
