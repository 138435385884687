import { getPublicPath } from 'utils/paths';

type UIWherePerk = { icon: string; text: string };
type UIWherePerks = { [key: string]: UIWherePerk };

export const UI_WHERE_PERKS: UIWherePerks = {
  diningRoom: {
    icon: getPublicPath('/images/icons/food.svg'),
    text: 'Comedor'
  },
  transport: {
    icon: getPublicPath('/images/icons/bus.svg'),
    text: 'Transporte Público'
  }
};

export type UIOfferWherePerk = {
  type: UIWherePerk;
  text: string;
};
