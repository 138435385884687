import { z } from 'zod';

export const TechSchema = z.object({
  id: z.number(),
  name: z.string(),
  icon: z
    .object({
      url: z.string(),
      alt: z.string().optional()
    })
    .optional(),
  isMainStack: z.boolean().optional()
});

export type Tech = z.infer<typeof TechSchema>;
