import { z } from 'zod';

export const LinksSchema = z.object({
  twitter: z.string().optional(),
  linkedin: z.string().optional(),
  stackoverflow: z.string().optional(),
  github: z.string().optional(),
  others: z.array(
    z.object({
      url: z.string(),
      title: z.string().optional()
    })
  )
});

export type Links = z.infer<typeof LinksSchema>;
