const shadow = {
  sm: '0px 0.76px 1.51px rgba(38, 26, 25, 0.06), 0px 0.76px 2.27px rgba(38, 26, 25, 0.1)',
  md: '0px 1.51px 5.3px rgba(38, 26, 25, 0.04), 0px 3.03px 4.54px rgba(38, 26, 25, 0.05)',
  lg: '0px 7.57px 11.35px rgba(38, 26, 25, 0.1), 0px 3.03px 4.54px rgba(38, 26, 25, 0.05)',
  xl: '0px 15.14px 18.92px rgba(38, 26, 25, 0.1), 0px 7.57px 7.57px rgba(38, 26, 25, 0.04)',
  xl2: '0px 18.92px 37.84px rgba(38, 26, 25, 0.25)',
  inner: 'inset 1.51px 1.51px 1.51px rgba(38, 26, 25, 0.1)',
  sm_dark:
    '0px 0.76px 1.51px rgba(38, 26, 25, 0.75), 0px 0.76px 2.27px #261A19',
  md_dark: '0px 1.51px 5.3px rgba(38, 26, 25, 0.75), 0px 3.03px 4.54px #261A19',
  lg_dark:
    '0px 7.57px 11.35px rgba(38, 26, 25, 0.5), 0px 3.03px 4.54px #261A19',
  xl_dark:
    '0px 15.14px 18.92px rgba(38, 26, 25, 0.5), 0px 7.57px 7.57px rgba(38, 26, 25, 0.04)',
  xl2_dark: '0px 18.92px 37.84px rgba(0, 0, 0, 0.5)',
  inner_dark: 'inset 1.51px 1.51px 1.51px rgba(0, 0, 0, 0.5)',
  card: '0 0 0 12px #fff, 0px 1.51px 5.3px 12px rgba(38, 26, 25, 0.04), 0px 3.03px 4.54px 12px rgba(38, 26, 25, 0.05)',
};

export default shadow;
