import { Button, Header, Paragraph } from '@asgard/ui-library';
import { faHome } from '@fortawesome/pro-solid-svg-icons';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { ErrorFallbackRoot } from './ErrorFallback.styled';
import { ErrorFallbackProps } from './ErrorFallback.types';

// Component.
// -----------------------------------------------------------------------------
export const ErrorFallback: React.FC<ErrorFallbackProps> = ({ onRetry }) => {
  // Hooks.
  // ---------------------------------------------------------------------------
  const { t } = useI18n('errorPage');
  const router = useRouter();

  // Handlers.
  // ---------------------------------------------------------------------------
  const handleOnClick = useCallback(() => {
    onRetry();
    router.push(getAppUrl(APP_URLS.home, router.locale));
  }, [onRetry, router]);

  // Render.
  // ---------------------------------------------------------------------------
  return (
    <ErrorFallbackRoot>
      <Header as="h2">{t('errorFallback.heading')}</Header>
      <Paragraph>{t('errorFallback.desc')}</Paragraph>
      <Button
        startIcon={faHome}
        label={t('errorFallback.cta')}
        onClick={handleOnClick}
      />
    </ErrorFallbackRoot>
  );
};
