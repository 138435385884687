import styled from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  radius,
  screen,
  spacing
} from 'theme';

export const StyledContainer = styled.div`
  color: ${color('text_subtle')};

  @media (min-width: ${screen('md')}) {
    min-height: ${spacing('xl8')};
  }

  @media (min-width: ${screen('lg')}) {
    font-size: ${fontSize('lg')};
  }

  > p {
    @media (min-width: ${screen('lg')}) {
      width: 100%;
    }
  }
`;

export const StyledOffer = styled.div`
  display: flex;
  flex-direction: column;

  color: ${color('text_default')};

  @media (min-width: ${screen('md')}) {
    flex-direction: row;
    border: 1px solid ${color('sand_200')};
    border-radius: ${radius('md')};
  }

  @media (min-width: ${screen('lg')}) {
    width: 100%;
  }
`;

export const StyledText = styled.div`
  margin-bottom: ${spacing('lg')};
`;

export const StyledOfferImage = styled.div`
  flex-shrink: 0;
  width: 100%;
  min-height: 13.75rem;
  position: relative;
  border-radius: ${radius('md')} ${radius('md')} 0 0;
  overflow: hidden;
  display: none;

  @media (min-width: ${screen('md')}) {
    width: 12.5rem;
    border-radius: ${radius('md')} 0 0 ${radius('md')};
    display: block;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--client-primary);
  }

  img {
    display: block;
    opacity: 0.25;
    filter: grayscale(1);
    transition: 0.25s;
    margin: auto;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const StyledOfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    padding: ${spacing('lg')};
  }
`;

export const StyledOfferLogo = styled.img`
  height: ${spacing('xl')};
  display: block;
`;

export const StyledOfferTitle = styled.span`
  font-size: ${fontSize('lg')};
  font-weight: ${fontWeight('medium')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl')};
  }
`;

export const StyledOfferDetails = styled.div`
  display: none;
  flex-wrap: wrap;
  gap: ${spacing('sm')} ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    display: flex;
  }
`;

export const StyledOfferDetailItemGroup = styled.div`
  display: flex;
  gap: ${spacing('md')};
`;

export const StyledOfferDetailItemSeparator = styled.span`
  align-self: flex-end;
  font-family: ${fontFamily('serif')};
  font-style: italic;
  font-size: ${fontSize('lg')};
  color: ${color('text_subtle')};
`;

export const StyledOfferDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('xs2')};
`;

export const StyledOfferDetailItemLabel = styled.span`
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('black')};
  text-transform: uppercase;
  color: ${color('text_subtle')};
`;

export const StyledOfferDetailItemValue = styled.span`
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('md')};

  span {
    font-weight: ${fontWeight('medium')};
    color: ${color('text_subtle')};
  }
`;

export const StyledOfferBadges = styled.div`
  display: none;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spacing('xs')};

  @media (min-width: ${screen('md')}) {
    display: flex;
  }
`;

export const StyledActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('md')};
  margin-top: ${spacing('lg')};

  @media (min-width: ${screen('md')}) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-top: ${spacing('md')};
  }
`;

export const StyledClosed = styled.div`
  width: 95px;
  height: 95px;
  color: ${color('text_white')};
  background-color: ${color('negative_500')};
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('black')};
  line-height: 1.33;
  text-transform: uppercase;
  border-radius: ${radius('full')};
  border: ${spacing('xs')} solid ${color('white')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-6deg) translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
`;
