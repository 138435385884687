import { useUser } from '@auth0/nextjs-auth0/client';
import { ManfrediteSettingsAvailabilityStatus } from 'interfaces/manfredite-settings.interface';
import {
  LoginType,
  ManfrediteProfileSummary,
  MANFREDITE_LEVEL
} from 'interfaces/manfredite.interface';
import { Offer } from 'interfaces/offer.interface';
import { useRouter } from 'next/router';
import { createContext, useEffect, useRef, useState } from 'react';
import { getProfileSummary } from 'services/profile.service';
import ServiceEventTracking from 'services/service.event-tracking';
import CookiesService, {
  COOKIES_NEXT_LOCALE
} from 'services/ui/cookies.service';
import { APP_URLS, getAppUrl } from 'utils/app-urls';

type Attempt = Pick<Offer, 'id' | 'position' | 'slug'> & {
  companyName: string;
};

export type BETA_FEATURES =
  | 'isLinkedinUpdate'
  | 'changePasswordSettings'
  | 'isLinkedinBeta'
  | 'salaryBenchmark'
  | 'offerAlerts'
  | 'techRolesFilter'
  | 'minLanguagesFilter'
  | 'talentPage';

export type LoggedUserContextProps = {
  loadingUser: boolean;
  userIsNotRegistered: boolean;
  user: ManfrediteProfileSummary | null;
  error: unknown | null;
  userIsYoungling: boolean;
  userIsPadawan: boolean;
  userIsJedi: boolean;
  publicProfileActive?: boolean;
  publicProfileSlug: string;
  availabilityStatus: ManfrediteSettingsAvailabilityStatus;
  userHasAttemptFor: (offerId: number) => boolean;
  refreshUserData: (attrs?: {
    level?: MANFREDITE_LEVEL;
    attempt?: Attempt | null;
    offerId?: number;
    name?: string;
    lastName?: string;
    avatarUrl?: string;
    availabilityStatus?: ManfrediteSettingsAvailabilityStatus;
    publicProfileActive?: boolean;
    publicProfileSlug?: string;
    preferredLanguage?: string;
    isResistance?: boolean;
  }) => Promise<void>;
  clearUserData: () => void;
  logoutUser: (locale?: string, returnUrl?: string) => void;
  hasFeature: (featureKey: BETA_FEATURES) => boolean;
  getFeature: (featureKey: BETA_FEATURES) => string | boolean | null;
};

export type RefreshUserDataArgs = Parameters<
  LoggedUserContextProps['refreshUserData']
>[0];

export const LoggedUserContext = createContext<LoggedUserContextProps>({
  loadingUser: false,
  userIsNotRegistered: false,
  user: null,
  error: null,
  availabilityStatus: 'ACTIVE',
  userIsYoungling: true,
  userIsPadawan: false,
  userIsJedi: false,
  publicProfileSlug: '',
  refreshUserData: async () => {
    // NOOP
  },
  clearUserData: () => {
    // NOOP
  },
  userHasAttemptFor: () => {
    return false;
  },
  logoutUser: () => {
    // NOOP
  },
  hasFeature: () => {
    return false;
  },
  getFeature: () => {
    return null;
  }
});

export const Auth0AuthTypes = [
  'auth0',
  'google-oauth2',
  'github',
  'linkedin'
] as const;
export type Auth0AuthType = typeof Auth0AuthTypes[number];

const LoginTypeAuth0Dict: Record<Auth0AuthType, LoginType> = {
  auth0: 'user_pass',
  'google-oauth2': 'google',
  github: 'github',
  linkedin: 'linkedin'
};

type LoginTypeText = {
  url: string;
  name: string;
};

export const LoginTypeTextDict: Record<LoginType, LoginTypeText> = {
  user_pass: {
    url: '',
    name: ''
  },
  github: {
    url: 'https://github.com',
    name: 'GitHub'
  },

  google: {
    url: 'https://google.com',
    name: 'Google'
  },

  linkedin: {
    url: 'https://linkedin.com',
    name: 'LinkedIn'
  }
};

export function getLoginTypeFromSub(
  sub?: string | null
): LoginType | undefined {
  const subType = sub?.split('|')[0] as Auth0AuthType;

  return subType ? LoginTypeAuth0Dict[subType] : undefined;
}

const LoggedUserProvider: React.FC = ({ children }) => {
  const { asPath, locale } = useRouter();

  const { user: auth0User, isLoading } = useUser();
  const [loadingUser, setLoadingUser] = useState(true);
  const [userIsNotRegistered, setUserIsNotRegistered] = useState(false);
  const [user, setUser] = useState<ManfrediteProfileSummary | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const polling = useRef<NodeJS.Timeout | null>(null);

  function setUserFromAuth0(): void {
    setUser({
      id: 42,
      email: auth0User?.email || '',
      name: auth0User?.name || '',
      lastName: '',
      preferredLanguage: 'EN',
      loginType: getLoginTypeFromSub(auth0User?.sub)
    });
  }

  function stopPolling(): void {
    if (polling.current) {
      clearInterval(polling.current);
    }
  }

  async function fetchUserData(): Promise<void> {
    try {
      const loggedUserInfo = await getProfileSummary();
      const userRegistered = loggedUserInfo && loggedUserInfo.registeredReady;
      const userNotRegisteredYet = !loggedUserInfo?.registeredReady;

      if (userRegistered) {
        stopPolling();

        loggedUserInfo.avatarUrl = loggedUserInfo.avatar?.url;

        setUser({
          ...loggedUserInfo,
          loginType: getLoginTypeFromSub(auth0User?.sub)
        });

        ServiceEventTracking.identifyUser(loggedUserInfo);

        setError(null);
        setLoadingUser(false);
        setUserIsNotRegistered(false);

        const preferredLang = loggedUserInfo.preferredLanguage.toLowerCase();

        // Explicitly set selected app language according to the user preferred lang
        CookiesService.add(COOKIES_NEXT_LOCALE, preferredLang);

        // If current app lang is not the same as the preferred lang
        // (I was in "es" but my preferred lang is "en") => redirect to the preferred lang
        if (locale !== preferredLang) {
          const targetPath = `/${preferredLang}${asPath}`;

          window.location.href = `${window.location.origin}${targetPath}`;
        }
      } else if (userNotRegisteredYet) {
        setUserIsNotRegistered(true);

        polling.current = setTimeout(fetchUserData, 2000);
      } else {
        stopPolling();
        setLoadingUser(false);

        const errorMessage = 'No user response from API';

        console.error(errorMessage);

        setError(new Error(errorMessage));
        setUserFromAuth0();
      }
    } catch (e) {
      console.error(`Error while fetching logged user data: ${e}`);

      stopPolling();
      setLoadingUser(false);
      setError(e);
      setUserFromAuth0();
    }
  }

  function getStringValueOrDefault(
    str: string | undefined,
    defaultValue: string
  ): string {
    return str === '' ? '' : str ?? defaultValue;
  }

  useEffect(() => {
    (async () => {
      if (auth0User && !isLoading) {
        if (!user) {
          fetchUserData();
        } else {
          setLoadingUser(false);
          setError(null);
        }
      }

      if (!auth0User && !isLoading) {
        setLoadingUser(false);
      }
    })();
  }, [auth0User, isLoading]);

  return (
    <LoggedUserContext.Provider
      value={{
        user,
        loadingUser,
        userIsNotRegistered,
        error,
        availabilityStatus: user?.configuration?.availabilityStatus || 'ACTIVE',
        publicProfileActive: user?.configuration?.publicProfileActive || false,
        publicProfileSlug: user?.configuration?.publicProfileSlug || '',
        userIsYoungling: user?.level === MANFREDITE_LEVEL.YOUNGLING,
        userIsPadawan: user?.level === MANFREDITE_LEVEL.PADAWAN,
        userIsJedi: user?.level === MANFREDITE_LEVEL.JEDI,

        refreshUserData: async (attrs): Promise<void> => {
          if (attrs && user) {
            let attempt = user.offers?.attempt;
            let applications = user.offers?.applications;

            if (attrs.attempt) {
              attempt = {
                offerId: attrs.attempt.id,
                position: attrs.attempt.position,
                slug: attrs.attempt.slug,
                companyName: attrs.attempt.companyName
              };
            } else if (attrs.attempt === null) {
              // Remove attempt
              attempt = undefined;
            }

            if (attrs.offerId) {
              applications = user.offers?.applications
                ? [...new Set([...user.offers.applications, attrs.offerId])]
                : [attrs.offerId];
            }

            setUser({
              ...user,
              configuration: {
                ...user.configuration,
                availabilityStatus:
                  attrs.availabilityStatus ||
                  user.configuration?.availabilityStatus,
                publicProfileActive:
                  attrs.publicProfileActive ??
                  user.configuration?.publicProfileActive,
                publicProfileSlug:
                  attrs.publicProfileSlug ||
                  user.configuration?.publicProfileSlug
              },
              preferredLanguage:
                attrs.preferredLanguage || user.preferredLanguage,
              isResistance: attrs.isResistance || user.isResistance,
              level: attrs.level || user.level,
              name: getStringValueOrDefault(attrs.name, user.name),
              lastName: getStringValueOrDefault(attrs.lastName, user.lastName),
              avatarUrl: getStringValueOrDefault(
                attrs.avatarUrl,
                user.avatarUrl || ''
              ),
              offers: {
                ...user.offers,
                attempt,
                applications
              }
            });

            return;
          }

          await fetchUserData();
        },

        userHasAttemptFor: (offerId: number): boolean => {
          return user?.offers?.attempt?.offerId === offerId;
        },

        clearUserData: (): void => {
          setUser(null);
        },

        logoutUser: (locale?: string, returnUrl?: string): void => {
          setUser(null);

          window.location.replace(
            `${window.location.origin}${getAppUrl(
              APP_URLS.logout,
              locale
            )}?returnUrl=${returnUrl || ''}`
          );
        },

        getFeature: (featureKey) => {
          if (!user || !user.features) {
            return null;
          }

          return user.features[featureKey];
        },

        hasFeature: (featureKey) => {
          const notFeatureFlaggedAnymore: BETA_FEATURES[] = [
            'changePasswordSettings',
            'offerAlerts',
            'techRolesFilter'
          ];

          if (notFeatureFlaggedAnymore.includes(featureKey)) {
            return true;
          }

          if (!user || !user.features) {
            return false;
          }

          return !!user.features[featureKey];
        }
      }}
    >
      {children}
    </LoggedUserContext.Provider>
  );
};
export default LoggedUserProvider;
