/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  radius,
  spacing
} from '../../theme';

const roleVariants: {
  [key: string]: {
    backgroundColor: (key: any) => any;
    borderColor: (key: any) => any;
    color: (key: any) => any;
  };
} = {
  primary: {
    backgroundColor: color('midnight_900'),
    borderColor: color('midnight_900'),
    color: color('text_default_inverse')
  },
  secondary: {
    backgroundColor: color('white'),
    borderColor: color('midnight_200'),
    color: color('text_default')
  },
  primaryInverse: {
    backgroundColor: color('midnight_200'),
    borderColor: color('midnight_200'),
    color: color('text_default')
  },
  secondaryInverse: {
    backgroundColor: color('midnight_900'),
    borderColor: color('midnight_700'),
    color: color('text_default_inverse')
  },
  highlight: {
    backgroundColor: color('midnight_200'),
    borderColor: color('midnight_200'),
    color: color('text_default')
  },
  light: {
    backgroundColor: color('white'),
    borderColor: color('midnight_200'),
    color: color('text_default')
  },
  negative: {
    backgroundColor: color('negative_600'),
    borderColor: color('negative_600'),
    color: color('white')
  },
  notice: {
    backgroundColor: color('notice_200'),
    borderColor: color('notice_500'),
    color: color('notice_900')
  },
  noticePrimary: {
    backgroundColor: color('notice_400'),
    borderColor: color('notice_400'),
    color: color('notice_900')
  },
  active: {
    backgroundColor: color('active'),
    borderColor: color('active'),
    color: color('text_over_active')
  }
};

const sizeVariants: {
  [key: string]: {
    paddingV: (key: any) => any;
    paddingH: (key: any) => any;
    fontSize: (key: any) => any;
  };
} = {
  xs: {
    paddingV: spacing('xs'),
    paddingH: spacing('sm'),
    fontSize: fontSize('xs')
  },
  md: {
    paddingV: spacing('md'),
    paddingH: spacing('lg'),
    fontSize: fontSize('md')
  }
};

export const Badge = styled.span<{
  variant?: string;
  sizes?: string;
  href?: string;
}>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid ${color('midnight_900')};
  border-radius: ${radius('full')};
  background-color: ${color('midnight_900')};
  color: ${color('text_default_inverse')};
  font-family: ${fontFamily('sans')};
  font-size: ${fontSize('xs')};
  line-height: 1;
  font-weight: ${fontWeight('black')};
  vertical-align: text-bottom;
  padding: ${spacing('xs')} ${spacing('md')};

  ${({ variant }) =>
    variant &&
    roleVariants[variant] &&
    css`
      background-color: ${roleVariants[variant].backgroundColor};
      border-color: ${roleVariants[variant].borderColor};
      color: ${roleVariants[variant].color};
    `}

  ${({ sizes }) =>
    sizes &&
    sizeVariants[sizes] &&
    css`
      padding: calc(${sizeVariants[sizes].paddingV} - 2px)
        ${sizeVariants[sizes].paddingH};
      font-size: ${sizeVariants[sizes].fontSize};
    `}
`;

export default Badge;
