import { TFunction } from 'hooks/useI18n';
import { ManfrediteProfileSummary } from 'interfaces/manfredite.interface';
import { FieldErrors, FieldValues } from 'react-hook-form';
import packageJSON from '../package.json';
import {
  EventTracking,
  PageTrackingArgs,
  PageTrackingEventName
} from './service.event-tracking.types';

const TRACK_LINK_DELAY_IN_MILLIS = 300;
const IDENTIFY_USER_PREFIX = 'userId-';
const PRE_ASGARD = 'preAsgard';
const POST_ASGARD = 'postAsgard';

const ServiceEventTracking = {
  /**
   * Sends a tracking event to the instrumentation service
   *
   * @param event - The event to be sent
   */
  page: (event: PageTrackingEventName, args: PageTrackingArgs): void => {
    const BLACKLIST = [
      'Website - Page Loaded',
      'Blog - Page Loaded',
      'Landing - Page Loaded'
    ];

    if (BLACKLIST.includes(event)) {
      console.info(`[ServiceEventTracking] ${event} is blacklisted`);
      return;
    }

    if (typeof global.analytics === 'undefined') {
      console.info(
        'Segment JS API not available. Maybe it has been blocked by an ad blocker?'
      );
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const properties = {
      campaign: {
        name: urlParams.get('utm_campaign')?.toLowerCase() || undefined,
        source: urlParams.get('utm_source')?.toLowerCase() || undefined,
        medium: urlParams.get('utm_medium')?.toLowerCase() || undefined,
        term: urlParams.get('utm_term')?.toLowerCase() || undefined,
        content: urlParams.get('utm_content')?.toLowerCase() || undefined,
        id: urlParams.get('utm_id')?.toLowerCase() || undefined
      }
    };

    global.analytics.page(
      event,
      {
        ...args,
        ...properties,
        debug: { build_version: packageJSON.version, package: '@asgard/talent' }
      },
      {
        context: {
          ...properties
        }
      }
    );
  },

  /**
   * Sends a tracking event to the instrumentation service
   *
   * @param event - The event to be sent
   */
  track: (
    { type, properties }: EventTracking,
    traits?: Record<string, unknown>
  ): void => {
    const BLACKLIST = ['Offers - Card Displayed'];

    if (BLACKLIST.includes(type)) {
      console.info(`[ServiceEventTracking] ${type} is blacklisted`);
      return;
    }

    if (typeof global.analytics === 'undefined') {
      console.info(
        'Segment JS API not available. Maybe it has been blocked by an ad blocker?'
      );
      return;
    }

    global.analytics.track(
      type,
      {
        ...properties,
        debug: { build_version: packageJSON.version, package: '@asgard/talent' }
      },
      traits
    );
  },

  trackLandingCtaClicked: (objectName: string): void => {
    ServiceEventTracking.track({
      type: 'Landing - CTA Clicked',
      properties: {
        objectName,
        url: window.location.href,
        title: document.title,
        urlParams: new URLSearchParams(window.location.search).toString()
      }
    });
  },

  trackLandingLinkClicked: (objectName: string): void => {
    ServiceEventTracking.track({
      type: 'Landing - Link Clicked',
      properties: {
        objectName,
        url: window.location.href,
        title: document.title,
        urlParams: new URLSearchParams(window.location.search).toString()
      }
    });
  },

  trackLandingFormFocused: (objectName: string): void => {
    ServiceEventTracking.track({
      type: 'Landing - Form Focused',
      properties: {
        objectName,
        url: window.location.href,
        urlParams: new URLSearchParams(window.location.search).toString()
      }
    });
  },

  trackExternalLandingCtaClicked: (
    e: React.MouseEvent,
    url: string,
    objectName: string
  ): void => {
    ServiceEventTracking.trackExternalLink(e, url, {
      type: 'Landing - CTA Clicked',
      properties: {
        objectName,
        url: window.location.href,
        title: document.title,
        urlParams: new URLSearchParams(window.location.search).toString()
      }
    });
  },

  /**
   * Sends a tracking event to the instrumentation service for a clicked **EXTERNAL** link.
   * It delays the redirection to the given `url` to give some time to the
   * tracking event to land before the redirection takes place.
   *
   * If the redirection is **internal** (using NextJS router), use `ServiceEventTracking.track()`.
   *
   * @param e - Click event
   * @param url - The link target **full URL**. i.e: https://example.com
   * @param event - The event to be sent
   */
  trackExternalLink: (
    e: React.MouseEvent,
    url: string,
    event: EventTracking
  ): void => {
    e.preventDefault();

    ServiceEventTracking.track(event);

    setTimeout(() => {
      window.location.replace(url);
    }, TRACK_LINK_DELAY_IN_MILLIS);
  },

  /**
   * Identifies a logged user againts the instrumentation service.
   *
   * @param user - The logged user
   */
  identifyUser: (user: ManfrediteProfileSummary): void => {
    if (!user.id) return;

    ServiceEventTracking.identify(user.id, {
      origin: user.legacyManfredite ? PRE_ASGARD : POST_ASGARD,
      preferredLanguage: (user.preferredLanguage || '')?.toLowerCase()
    });
  },

  /**
   * Identifies aid againts the instrumentation service.
   *
   * @param user - The logged user
   */
  identify: (id: number, traits: Record<string, unknown> = {}): void => {
    if (typeof global.analytics === 'undefined') {
      console.info(
        'Segment JS API not available. Maybe it has been blocked by an ad blocker?'
      );
      return;
    }

    global.analytics.identify(`${IDENTIFY_USER_PREFIX}${id}`, traits);
  },

  /**
   * Identifies an anonymous user againts the instrumentation service.
   *
   * @param traits - The user traits
   */
  identifyAnonymous: (traits: Record<string, unknown> = {}): void => {
    if (typeof global.analytics === 'undefined') {
      console.info(
        'Segment JS API not available. Maybe it has been blocked by an ad blocker?'
      );
      return;
    }

    global.analytics.identify(traits);
  },

  /**
   * Sends a `Onboarding - Form issues` tracking event, using provided
   * form errors and form values to build the event properties
   *
   * @param errorList - The form validation errors
   * @param formValues - The current form fields values
   */
  trackFormErrors: <T extends FieldValues>(
    errorList: FieldErrors<T>,
    formValues: unknown,
    t?: TFunction
  ): void => {
    console.group('[trackFormErrors] Validation errors');
    console.debug('Errors', errorList);
    console.debug('Form content', formValues);

    const errors = extractErrorMessages(errorList) || [];

    errors.forEach((error) => {
      if (error.message) {
        const trackInfo: EventTracking = {
          type: 'Onboarding - Form issues',
          properties: {
            fieldLabel: error.key,
            formContent: formValues,
            issueContent: t ? t(error.message) : error.message
          }
        };

        console.debug('Error event', trackInfo);
        ServiceEventTracking.track(trackInfo);
      }
    });

    console.groupEnd();
  }
};

function extractErrorMessages(errorsList: FieldErrors): {
  message: string;
  key: string;
}[] {
  const errors: {
    message: string;
    key: string;
  }[] = [];

  for (const key in errorsList) {
    if (Object.prototype.hasOwnProperty.call(errorsList, key)) {
      const value = errorsList[key];

      if (value && typeof value === 'object') {
        if ('message' in value) {
          errors.push({
            key,
            message: value.message?.toString() || ''
          });
        } else {
          const nestedMessages = extractErrorMessages(value as FieldErrors);
          errors.push(...nestedMessages);
        }
      }
    }
  }

  return errors;
}

export default ServiceEventTracking;
