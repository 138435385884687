import { ReactNode } from 'react';
import {
  AppearanceTypes,
  useToasts as useToastsLib
} from 'react-toast-notifications';

type ToastOptions = {
  autoDismiss?: boolean;
  loading?: boolean;
};

export type ShowToastFunction = (
  msg: ReactNode,
  title?: ReactNode,
  opts?: ToastOptions
) => void;

export const useToasts = (): {
  addSuccessToast: ShowToastFunction;
  addWarningToast: ShowToastFunction;
  addErrorToast: ShowToastFunction;
  addInfoToast: ShowToastFunction;
  addLoadingToast: ShowToastFunction;
  clearToasts: () => void;
} => {
  const { addToast, removeAllToasts } = useToastsLib();

  function showMessage(
    msg: ReactNode,
    type: AppearanceTypes,
    title?: ReactNode,
    opts?: ToastOptions
  ): void {
    const { loading, autoDismiss } = {
      loading: false,
      autoDismiss: true,
      ...opts
    };

    addToast(msg, {
      appearance: type,
      title,
      loading: loading,
      autoDismiss: autoDismiss
    });
  }

  return {
    addSuccessToast: (msg, title, opts) => {
      showMessage(msg, 'success', title, opts);
    },
    addWarningToast: (msg, title, opts) => {
      showMessage(msg, 'warning', title, opts);
    },
    addErrorToast: (msg, title, opts) => {
      showMessage(msg, 'error', title, opts);
    },
    addInfoToast: (msg, title, opts) => {
      showMessage(msg, 'info', title, opts);
    },
    addLoadingToast: (msg, title, opts) => {
      showMessage(msg, 'info', title, {
        ...opts,
        loading: true
      });
    },
    clearToasts: () => {
      removeAllToasts();
    }
  };
};
