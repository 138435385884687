import { z } from 'zod';

export const MediaSummarySchema = z.object({
  id: z.number(),
  title: z.string(),
  filename: z.string().optional(),
  url: z.string(),
  alt: z.string().optional()
});

export type MediaSummary = z.infer<typeof MediaSummarySchema>;
