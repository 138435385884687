const zindex = {
  pageNav: 3,
  mobileNav: 2,
  heroBg: -1,
  heroBgGradient: 1,
  heroSectionBg: 0,
  heroSectionBgImg: -1,
  heroContent: 1,
  bottomBar: 2,
  modal: 4,
  filters: 4,
  filtersBg: 3,
  scrollTop: 3,
  spinnerSlot: 3,
  levelBar: 2,
  profileNavBar: 3,
  dropDown: 2,
  drawer: 3,
  drawerCloseButton: 1,
  portalSidebar: 3,
  talentTabs: 2,
  talentEngineNav: 3
  };

export default zindex;
