import ButtonLink from 'components/shared/ButtonLink.styled';
import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { useI18n } from 'hooks/useI18n';
import { withTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef } from 'react';
import OfferApplicationService from 'services/offer-application.service';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { StyledContainer } from './OfferApplyModal.styled';
import { StyledActions } from './OfferApplyModalAplicationComplete.styled';
import OfferApplyModalPositionCard from './OfferApplyModalPositionCard';
import { OfferApplyModalPositionCloseProps } from './OfferApplyModalPositionClose.types';

const OfferApplyModalPositionClose: React.FC<
  OfferApplyModalPositionCloseProps
> = ({ applyModalClose, uiOffer, offerId }) => {
  const { t } = useI18n('apply-modal');
  const { locale } = useRouter();
  const { userHasAttemptFor, refreshUserData } = useContext(LoggedUserContext);
  const deleteAttempt = useRef<boolean>(false);

  useEffect(() => {
    if (userHasAttemptFor(offerId) && !deleteAttempt.current) {
      refreshUserData({
        attempt: null
      });

      deleteAttempt.current = true;

      OfferApplicationService.dismissApplicationAttempt(offerId);
    }
  }, []);

  return (
    <StyledContainer>
      <p>{t('offer-close.subtitle')}</p>

      <OfferApplyModalPositionCard uiOffer={uiOffer} />
      <StyledActions>
        <Link
          href={`${getAppUrl(APP_URLS.jobOffer, locale)}?locale=${locale}`}
          passHref
        >
          <ButtonLink
            variant="primary"
            id="modal-not-go-button"
            aria-label={t('offer-close.CTA-offers')}
            sizes="md"
            onClick={() => {
              applyModalClose();
            }}
          >
            <span aria-hidden="true">{t('offer-close.CTA')}</span>
          </ButtonLink>
        </Link>
      </StyledActions>
    </StyledContainer>
  );
};

export default withTranslation(['apply-modal'])(OfferApplyModalPositionClose);
