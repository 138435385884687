import { useTranslation as useTranslationLib } from 'next-i18next';
import { useRef } from 'react';
import { UseI18n, UseI18nReturn } from './useI18n.types';

export const useI18n: UseI18n = (ns) => {
  const { t } = useTranslationLib(ns);

  const tFunction = useRef<UseI18nReturn['t']>((key, options = {}) => {
    return `${t(key, options)}` || '';
  });

  return {
    t: tFunction.current
  };
};
