import { z } from 'zod';

export const SalarySchema = z.object({
  id: z.number(),
  currency: z.string(),
  min: z.number().optional().nullable(),
  max: z.number().optional().nullable()
});

export type Salary = z.infer<typeof SalarySchema>;
