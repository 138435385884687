import { ManfrediteProfileSummary } from 'interfaces/manfredite.interface';
import { config } from 'utils/config';
import * as fetch from 'utils/fetch';
import { getAuthHeader } from 'utils/headers';

const ENDPOINT_PATH = '/manfredite/profile';
const NEXT_API_BASE_URL = `${config.appUrl}/api${ENDPOINT_PATH}`;
const API_BASE_URL = `${config.apiUrl}${ENDPOINT_PATH}`;

const ProfileService = {
  /**
   * Gets the profile summary data for current logged user
   *
   * @returns the profile summary data
   */
  getProfileSummary: async (
    token?: string
  ): Promise<ManfrediteProfileSummary> => {
    const baseUrl = token ? API_BASE_URL : NEXT_API_BASE_URL;
    const res = await fetch.get<{
      data: ManfrediteProfileSummary;
    }>(`${baseUrl}/profile-summary`, getAuthHeader(token));

    return res.data;
  }
};

export default ProfileService;
export const getProfileSummary = ProfileService.getProfileSummary;
