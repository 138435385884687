import { createContext } from 'react';
import { ManfrediteFieldsStatusContextProps } from './manfredite-fields-status.context.types';

export const ManfrediteFieldsStatusContext =
  createContext<ManfrediteFieldsStatusContextProps>({
    fieldsStatus: {
      pendingFieldsForOfferApplying: [],
      fulfilledFieldsForOfferApplying: []
    },
    userCanApplyToOffers: false,
    userCouldApplyToOffers: false,
    isLoading: true,
    isManfrediteSaved: true,
    markManfrediteAsSaved: () => {
      // NOOP
    }
  });
