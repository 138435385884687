import {
  ManfrediteRuleName as ManfrediteRuleNameCommon,
  ManfrediteRulesResponse
} from '@asgard/api-common';
import {
  ManfrediteOfferApplyRules,
  ManfrediteRuleName
} from 'model/manfredite-offer-apply-rules';

export function normalizeToOfferApplyRules(
  res: ManfrediteRulesResponse
): ManfrediteOfferApplyRules {
  return {
    fulfilledFieldsForOfferApplying:
      res.data.fulfilledRules?.map((field) => MANFREDITE_RULES_DICT[field]) ||
      [],
    pendingFieldsForOfferApplying:
      res.data.unfulfilledRules?.map((field) => MANFREDITE_RULES_DICT[field]) ||
      []
  };
}

// TODO: Should we maintain this? Or…
// ? Should I type this as string on our model and forget about strict typing
// ? Should I type it in our model as the `ManfrediteRuleName` from @asgard/common-dto
// ? Should I maintain this dictionary just to have the chance to decouple from @asgard/common-dto
// ? Many doubts…
const MANFREDITE_RULES_DICT: Record<
  ManfrediteRuleNameCommon,
  ManfrediteRuleName
> = {
  'first-name': 'first-name',
  'last-name': 'last-name',
  techs: 'techs',
  location: 'location',
  'salary-range': 'salary-range',
  'career-goals': 'career-goals',
  introduction: 'introduction',
  phone: 'phone',
  experience: 'experience',
  language: 'language',
  roles: 'roles',
  'cv-reference': 'cv-reference'
};
