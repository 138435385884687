import { Trans } from 'next-i18next';

const HtmlTrans: React.FC<React.ComponentProps<typeof Trans>> = ({
  components,
  ...other
}) => {
  return (
    <Trans
      {...other}
      components={{
        ul: <ul />,
        ol: <ol />,
        li: <li />,
        p: <p />,
        a: <a />,
        strong: <strong />,
        b: <b />,
        em: <em />,
        u: <u />,
        code: <code />,
        span: <span />,
        ...components
      }}
    />
  );
};

export default HtmlTrans;
