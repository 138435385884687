const brand = {
  white: '#ffffff',
  black: '#000000',
  sand_100: '#f8f7f3',
  sand_150: '#ebe8df',
  sand_200: '#ded9cc',
  sand_300: '#c4b9a7',
  sand_400: '#aa9987',
  sand_500: '#8f7a6a',
  sand_600: '#755d52',
  sand_700: '#5a443d',
  sand_800: '#402d2a',
  sand_850: '#332321',
  sand_900: '#261a19',
  midnight_100: '#faf9ff',
  midnight_150: '#eceaf1',
  midnight_200: '#dedce4',
  midnight_300: '#c0bec9',
  midnight_400: '#a29eae',
  midnight_500: '#837e93',
  midnight_600: '#676178',
  midnight_700: '#4d485c',
  midnight_800: '#353241',
  midnight_850: '#2a2734',
  midnight_900: '#1f1d26',
  navy_100: '#e6fcff',
  navy_150: '#cef0f6',
  navy_200: '#b7e2ec',
  navy_300: '#8dc3d7',
  navy_400: '#69a2c2',
  navy_500: '#4981ad',
  navy_600: '#2e6197',
  navy_700: '#184382',
  navy_800: '#092c64',
  navy_850: '#042252',
  navy_900: '#001940',
  emerald_100: '#e6fff7',
  emerald_150: '#c9f8eb',
  emerald_200: '#aef1e1',
  emerald_300: '#86ddcf',
  emerald_400: '#5ecabf',
  emerald_500: '#35b7b2',
  emerald_600: '#0DA1A4',
  emerald_700: '#038185',
  emerald_800: '#006266',
  emerald_850: '#005153',
  emerald_900: '#003f40',
  indigo_100: '#e6dfff',
  indigo_300: '#b3a2f1',
  indigo_500: '#7d64d6',
  indigo_700: '#472ca6',
  indigo_900: '#1e076e',
  cyan_100: '#dffffd',
  cyan_300: '#a5eeef',
  cyan_500: '#6dcdd8',
  cyan_700: '#3ba0b7',
  cyan_900: '#147092',
  mint_100: '#dffff1',
  mint_300: '#b3fee3',
  mint_500: '#84f6d6',
  mint_700: '#53e4c8',
  mint_900: '#28cebc',
  purple_100: '#ffdfec',
  purple_300: '#f9b7d7',
  purple_500: '#ec8cc2',
  purple_700: '#b8149c',
  purple_900: '#b93a98',
  sun_100: '#fff8df',
  sun_300: '#fff3bc',
  sun_500: '#f8eb95',
  sun_700: '#e6dc6b',
  sun_900: '#d1cb44',
  pink_500: '#EC8CC2'
};

const semantic = {
  negative_100: '#FFE2D3',
  negative_150: '#FDD1BD',
  negative_200: '#FCC0A7',
  negative_300: '#F6997A',
  negative_400: '#EC704F',
  negative_500: '#DE492B',
  negative_600: '#CB2811',
  negative_700: '#B31102',
  negative_800: '#980600',
  negative_850: '#8C0300',
  negative_900: '#800000',
  notice_100: '#FFEFCF',
  notice_150: '#FDE6B7',
  notice_200: '#FCDDA0',
  notice_300: '#F6C96F',
  notice_400: '#ECB341',
  notice_500: '#DE9D1B',
  notice_600: '#CB8801',
  notice_700: '#B37700',
  notice_800: '#986600',
  notice_850: '#8C5D00',
  notice_900: '#805500',
  positive_100: '#EAFFBF',
  positive_150: '#E0FDB0',
  positive_200: '#D6FAA2',
  positive_300: '#BBF383',
  positive_400: '#9BE764',
  positive_500: '#78D549',
  positive_600: '#56BB34',
  positive_700: '#389C24',
  positive_800: '#217A19',
  positive_850: '#196915',
  positive_900: '#125912',
  info_100: '#D9F9FF',
  info_150: '#C2F2FD',
  info_200: '#ADECFA',
  info_300: '#7FDBF3',
  info_400: '#54C5E7',
  info_500: '#30ABD5',
  info_600: '#168FBB',
  info_700: '#07719C',
  info_800: '#01547A',
  info_850: '#014869',
  info_900: '#003B59',
};

const qualitative ={
  pastel1_01: '#FBB4AE',
  pastel1_02: '#B3CDE3',
  pastel1_03: '#CCEBC5',
  pastel1_04: '#DECBE4',
  pastel1_05: '#FED9A6',
  pastel1_06: '#FFFFCC',
  pastel1_07: '#E5D8BD',
  pastel1_08: '#FDDAEC',
  pastel1_09: '#F2F2F2',
  set1_01: '#E51A1D',
  set1_02: '#377EB9',
  set1_03: '#4DB04A',
  set1_04: '#974EA2',
  set1_05: '#FF8000',
  set1_06: '#FFFF33',
  set1_07: '#A45528',
  set1_08: '#F782C1',
  set1_09: '#999999',
  set2_01: '#66C2A5',
  set2_02: '#FC8F64',
  set2_03: '#8C9ECA',
  set2_04: '#E788C3',
  set2_05: '#A6D855',
  set2_06: '#FFD92E',
  set2_07: '#FEE8C8',
  set2_08: '#999999',
  set3_01: '#8DD3C7',
  set3_02: '#FFFFB3',
  set3_03: '#BDB9DA',
  set3_04: '#FB8274',
  set3_05: '#7EAFD3',
  set3_06: '#FDB563',
  set3_07: '#B3DE68',
  set3_08: '#FCCFE6',
  set3_09: '#D9D9D9',
  set3_10: '#BC7FBD',
  set3_11: '#CDEBC6',
  set3_12: '#FFEE70',
  dark2_01: '#1B9D76',
  dark2_02: '#D95F02',
  dark2_03: '#746FB3',
  dark2_04: '#E7278A',
  dark2_05: '#65A41E',
  dark2_06: '#E3A702',
  dark2_07: '#A6761D',
  dark2_08: '#666666',
}

const sequential ={
  seq_blue_01: '#F7FBFF',
  seq_blue_02: '#DEEBF7',
  seq_blue_03: '#C7DCEF',
  seq_blue_04: '#9ECAE1',
  seq_blue_05: '#6BAED6',
  seq_blue_06: '#4292C6',
  seq_blue_07: '#2171B5',
  seq_blue_08: '#08519C',
  seq_blue_09: '#08306B',
  gnbu_01: '#F7FCF0',
  gnbu_02: '#E0F3DB',
  gnbu_03: '#CDEBC6',
  gnbu_04: '#A8DDB5',
  gnbu_05: '#7BCCC4',
  gnbu_06: '#50B4D3',
  gnbu_07: '#2B8CBE',
  gnbu_08: '#0868AC',
  gnbu_09: '#084081',
  ylgnbu_01: '#FFFFD9',
  ylgnbu_02: '#EDF8B1',
  ylgnbu_03: '#C7E9B4',
  ylgnbu_04: '#7FCDBB',
  ylgnbu_05: '#41B6C4',
  ylgnbu_06: '#1D91C0',
  ylgnbu_07: '#225EA8',
  ylgnbu_08: '#253494',
  ylgnbu_09: '#081D58',
}

const interaction = {
  active: '#01FFC6',
  current: '#0ca',
  selected: 'rgba(1, 255, 198, 0.25)',
};

const colorUI = {
  text_black: brand.black,
  text_white: brand.white,
  text_default: brand.midnight_800,
  text_subtle: brand.midnight_500,
  text_default_inverse: brand.midnight_200,
  text_subtle_inverse: brand.midnight_500,
  text_placeholder: brand.midnight_300,
  text_placeholder_inverse: brand.midnight_700,
  text_disabled: brand.midnight_200,
  text_disabled_inverse: brand.midnight_800,
  link: brand.emerald_600,
  link_hover: brand.indigo_700,
  link_active: brand.purple_700,
  link_inverse: brand.emerald_400,
  link_inverse_hover: brand.indigo_500,
  link_inverse_active: brand.purple_500,
  text_active: '#00ccaa',
  text_active_dark: interaction.active,
  text_over_active: brand.navy_800,
  text_selected: brand.navy_800,
  text_selected_inverse: interaction.active,
  focus: interaction.active,
};

const color = {
  ...brand,
  ...semantic,
  ...interaction,
  ...colorUI,
  ...qualitative,
  ...sequential
};

export default color;
