import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faSpinnerThird,
  faTimesCircle
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/shared/Button.styled';
import { LinkText } from 'components/shared/LinkText';
import { useI18n } from 'hooks/useI18n';
import { Trans, withTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { StyledContainer } from './OfferApplyModal.styled';
import { StyledActions } from './OfferApplyModalAplicationComplete.styled';
import { OfferApplyModalAplicationCompleteProps } from './OfferApplyModalAplicationComplete.types';
import OfferApplyModalPositionCard from './OfferApplyModalPositionCard';

const OfferApplyModalAplicationComplete: React.FC<
  OfferApplyModalAplicationCompleteProps
> = ({
  handleNoClick,
  uiOffer,
  handleYesClick,
  isSubmittingYes,
  isSubmittingNo
}) => {
  const { t } = useI18n('apply-modal');
  const router = useRouter();
  return (
    <StyledContainer>
      <p>
        <Trans
          i18nKey={'subtitle.userHasApplied'}
          ns="apply-modal"
          components={{
            linkProfile: (
              <LinkText
                href={getAppUrl(APP_URLS.portalV2Dashboard, router.locale)}
                onClick={async (e) => {
                  e.preventDefault();
                  await router.push(
                    getAppUrl(APP_URLS.portalV2Dashboard, router.locale)
                  );

                  await handleNoClick();
                }}
              />
            ),
            linkOffers: (
              <LinkText
                href={getAppUrl(APP_URLS.jobOffers, router.locale)}
                onClick={async (e) => {
                  e.preventDefault();
                  await router.push(
                    getAppUrl(APP_URLS.jobOffers, router.locale)
                  );

                  await handleNoClick();
                }}
              />
            )
          }}
        />
      </p>

      <OfferApplyModalPositionCard uiOffer={uiOffer} />
      <StyledActions>
        <Button
          variant="primary"
          id="modal-apply-button"
          aria-label={t('actions.yes')}
          onClick={() => {
            handleYesClick();
          }}
          isLoading={isSubmittingYes}
        >
          {isSubmittingYes && <FontAwesomeIcon icon={faSpinnerThird} spin />}
          {!isSubmittingYes && <FontAwesomeIcon icon={faCheckCircle} />}

          <span aria-hidden="true">{t('actions.yes')}</span>
        </Button>
        <Button
          variant="secondary"
          id="modal-cancel-button"
          aria-label={t('actions.no')}
          onClick={() => {
            handleNoClick();
          }}
          isLoading={isSubmittingNo}
        >
          {isSubmittingNo && <FontAwesomeIcon icon={faSpinnerThird} spin />}

          {!isSubmittingNo && <FontAwesomeIcon icon={faTimesCircle} />}
          <span aria-hidden="true">{t('actions.no')}</span>
        </Button>
      </StyledActions>
    </StyledContainer>
  );
};

export default withTranslation(['apply-modal'])(
  OfferApplyModalAplicationComplete
);
