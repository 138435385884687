import { Multilingual } from 'interfaces/offer.interface';
import { createContext, useState } from 'react';

type SlugsContextProps = {
  slug: Multilingual | null;
  clearSlug: () => void;
  setSlug: (slug: Multilingual) => void;
  hasSlugForLang: (locale?: string) => boolean;
};

export const SlugsContext = createContext<SlugsContextProps>({
  slug: null,
  clearSlug: () => {
    //NOOP
  },
  setSlug: () => {
    //NOOP
  },
  hasSlugForLang: () => {
    //NOOP
    return false;
  }
});

const SlugsProvider: React.FC = ({ children }) => {
  const [slug, setSlug] = useState<Multilingual | null>(null);

  return (
    <SlugsContext.Provider
      value={{
        slug: slug,
        clearSlug: () => setSlug(null),
        setSlug: (newSlug: Multilingual) => setSlug(newSlug),
        hasSlugForLang: (locale?: string) => {
          //NOOP
          return !!slug && !!locale && !!slug[locale];
        }
      }}
    >
      {children}
    </SlugsContext.Provider>
  );
};
export default SlugsProvider;
