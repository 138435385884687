import { FetchError } from 'model';

export const getErrorMessage = (error: unknown): string =>
  error instanceof FetchError
    ? error.message
    : typeof (error as Error).message === 'object'
    ? JSON.stringify((error as Error).message)
    : (error as Error).message || (error as Error).toString();

export const getErrorCode = (error: FetchError): number => error.statusCode;
