import {
  ManfrediteRulesResponse,
  MultilingualLanguages
} from '@asgard/api-common';
import { ManfrediteOfferApplyRules } from 'model/manfredite-offer-apply-rules';
import { config } from 'utils/config';
import * as fetch from 'utils/fetch';
import { getAuthHeader } from 'utils/headers';
import { normalizeToOfferApplyRules } from './manfredite-rules.service.normalizer';

const ENDPOINTS_PATH = {
  offerApply: '/v2/manfredite/rules'
};

const NEXT_API_BASE_URL = `${config.appUrl}/api`;

let offerApplyGetController: AbortController | undefined;

// Service
// -----------------------------------------------------------------------------
export const ManfrediteRulesService = {
  // OFFER APPLY
  // --------------------------------------------------------------------------
  offerApply: {
    // GET
    get: async (
      lang: MultilingualLanguages,
      token?: string
    ): Promise<ManfrediteOfferApplyRules> => {
      // * We only want the newest request to be resolved.
      // * Old ones are not needed, so they can be cancelled.
      if (offerApplyGetController) {
        offerApplyGetController.abort();
      }
      offerApplyGetController = new AbortController();

      const path = token
        ? `${config.apiUrl}${ENDPOINTS_PATH['offerApply']}`
        : `${NEXT_API_BASE_URL}${ENDPOINTS_PATH['offerApply']}`;

      const res = await fetch.get<ManfrediteRulesResponse>(`${path}`, {
        ...getAuthHeader(token),
        signal: offerApplyGetController.signal
      });

      return normalizeToOfferApplyRules(res);
    }
  }
};
