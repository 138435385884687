const spacing = {
  xs3: '2',
  xs2: '4',
  xs: '8',
  sm: '12',
  md: '16',
  mdlg: '20',
  lg: '24',
  xl: '32',
  xl2: '40',
  xl3: '48',
  xl4: '64',
  xl5: '96',
  xl6: '160',
  xl7: '192',
  xl8: '240',
};

export default spacing;
