import {
  LanguageLevel,
  Manfredite as CommonManfredite,
  ManfrediteCareerJobPosition as CommonManfrediteCareerJobPosition,
  ManfrediteConfigurationPublicProfileViewsType,
  ManfrediteCvApplication,
  ManfrediteEducation as CommonManfrediteEducation,
  ManfrediteHighlight as CommonManfrediteHighlight,
  ManfrediteProject as CommonManfrediteProject
} from '@asgard/common-dto';
import { ManfrediteSettings } from './manfredite-settings.interface';
import { Media, Multilingual, Offer, Tech } from './offer.interface';
import { PublicManfredite } from './public-manfredite.interface';

export enum MANFREDITE_LEVEL {
  YOUNGLING = 'YOUNGLING',
  PADAWAN = 'PADAWAN',
  JEDI = 'JEDI'
}

export interface ManfrediteApplicationAttempt {
  offerId: number;
  position: Multilingual;
  slug: Multilingual;
  companyName: string;
}

export type LoginType = 'google' | 'github' | 'linkedin' | 'user_pass';

export interface ManfrediteProfileSummary {
  id?: number;
  name: string;
  lastName: string;
  email?: string;
  avatar?: Media;
  avatarUrl?: string;
  level?: MANFREDITE_LEVEL;
  configuration?: ManfrediteSettings;
  registeredReady?: boolean;
  legacyManfredite?: boolean;
  preferredLanguage: string;
  isResistance?: boolean;
  features?: Record<string, string | boolean>;

  loginType?: LoginType;

  offers?: {
    applications?: (string | number)[];
    attempt?: ManfrediteApplicationAttempt;
  };
}

export interface TechRole {
  id: number;
  name: Multilingual;
}

export interface ProfileLinks {
  linkedin?: string;
  twitter?: string;
  github?: string;
  stackoverflow?: string;
  others?: ProfileLink[];
}

export interface ProfileLink {
  url: string;
  desc?: string;
}

export interface Profile {
  id: number;
  email: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  avatar?: Media;
  links?: ProfileLinks;
  offers?: Offer[];
  media?: Media[];
  user?: User;
}

export interface User {
  id: number;
  status: string;
  userType: string;
  profile: Profile;
  profileId: number;
}

export type ManfrediteCareerJobPosition = CommonManfrediteCareerJobPosition;

export type ManfrediteProject = CommonManfrediteProject;

export type ManfrediteHighlight = CommonManfrediteHighlight;

export type ManfrediteEducation = CommonManfrediteEducation;

export interface ManfrediteCertification {
  id: number;
  title: Multilingual;
  description?: Multilingual;
  from: Date;
  to?: Date;
  techs?: Tech[];
  issuingOrganization: string;
  logo?: Media;
  url?: string;
}

export interface ManfrediteBookmark {
  id: number;
  type: 'READING' | 'WEBSITE' | 'PODCAST' | 'VIDEO' | 'OTHER';
  category?: Multilingual;
  title: Multilingual;
  author?: string;
  description?: Multilingual;
  logo?: Media;
  url: string;
}

export type Manfredite = CommonManfredite & {
  createdAt: Date;
  updatedAt: Date;
  locationsComments?: Multilingual;
};

export type ManfrediteGithubSync = {
  repositoryOwner: string;
  repositoryName: string;
};

export interface ManfrediteFact {
  title: Multilingual;
  description: Multilingual;
}

export interface SalaryRange {
  id: number;
  currency: string;
  min: number;
  max: number;
}

export interface Language {
  id: number;
  code: string;
  name: Multilingual;
  level: LanguageLevel;
}

export interface Experience {
  id: number;
  min: number;
  max: number;
}

export interface CommunicationPolicy {
  newsletter: boolean;
}

export interface PublicManfrediteJobApplication {
  publicManfredite?: PublicManfredite;
  jobApplication?: ManfrediteCvApplication;
  jobApplicationResume: PublicManfrediteJobApplicationResume;
}

export type PublicManfrediteJobApplicationResume = Pick<
  Profile,
  'firstName' | 'lastName'
> &
  Pick<Manfredite, 'experience' | 'roles'> & {
    jobPosition?: Multilingual;
    companyName: Multilingual;
    view: ManfrediteConfigurationPublicProfileViewsType;
  };
