import { z } from 'zod';

export const LANGUAGES_LEVELS = [
  'not-defined',
  'basic',
  'intermediate',
  'fluid',
  'native'
] as const;

export type LanguageLevel = typeof LANGUAGES_LEVELS[number];

export const LanguageSchema = z.object({
  id: z.number(),
  code: z.string(),
  name: z.string(),
  level: z.enum(LANGUAGES_LEVELS)
});

export type Language = z.infer<typeof LanguageSchema>;
