export const OFFER_FILTER_QUERY_PARAM_ONLY_ACTIVE = 'onlyActive';
export const OFFER_FILTER_QUERY_PARAM_REMOTE_OPTIONS = 'remoteOptions';
export const OFFER_FILTER_QUERY_PARAM_MIN_SALARY = 'minSalary';
export const OFFER_FILTER_QUERY_PARAM_TEXT = 'text';
export const OFFER_FILTER_QUERY_PARAM_LOCATIONS = 'locations';
export const OFFER_FILTER_QUERY_PARAM_CURRENCY = 'currency';
export const OFFER_FILTER_QUERY_PARAM_OFFER_LANGUAGE = 'offerLanguage';
export const OFFER_FILTER_QUERY_PARAM_TECHS = 'techs';
export const OFFER_FILTER_QUERY_PARAM_TECH_ROLES = 'techRoles';
export const OFFER_FILTER_QUERY_PARAM_MIN_LANGUAGES = 'minLanguages';

export const OFFER_FILTER_QUERY_PARAMS = [
  OFFER_FILTER_QUERY_PARAM_ONLY_ACTIVE,
  OFFER_FILTER_QUERY_PARAM_REMOTE_OPTIONS,
  OFFER_FILTER_QUERY_PARAM_MIN_SALARY,
  OFFER_FILTER_QUERY_PARAM_TEXT,
  OFFER_FILTER_QUERY_PARAM_LOCATIONS,
  OFFER_FILTER_QUERY_PARAM_CURRENCY,
  OFFER_FILTER_QUERY_PARAM_OFFER_LANGUAGE,
  OFFER_FILTER_QUERY_PARAM_TECHS,
  OFFER_FILTER_QUERY_PARAM_TECH_ROLES,
  OFFER_FILTER_QUERY_PARAM_MIN_LANGUAGES
];
