import { ListItemStringValue } from 'interfaces/ui/ui-list-item';

export const MANFREDITE_BOOKMARK_TYPES: ListItemStringValue[] = [
  {
    label: 'WEBSITE',
    value: 'WEBSITE'
  },

  {
    label: 'READING',
    value: 'READING'
  },

  {
    label: 'PODCAST',
    value: 'PODCAST'
  },

  {
    label: 'VIDEO',
    value: 'VIDEO'
  },

  {
    label: 'OTHER',
    value: 'OTHER'
  }
];
