import { i18n } from 'next-i18next';

export function getTranslation(
  key: string,
  ns: string,
  locale?: string,
  opts?: Record<string, string | number>
): string {
  return i18n?.t(key, { ns, lng: locale, ...opts }) || '';
}
