/**
 * Returns an Authorization header object with the given token. If no token is provided, an empty object is returned
 *
 * @param token - the token. If not present, empty header is returned.
 * @returns The authorization header with the given token, or an empty headers object if no token is provided
 */
export function getAuthHeader(token?: string): RequestInit | undefined {
  return token
    ? {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    : undefined;
}
