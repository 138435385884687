import { z } from 'zod';
import { MediaSummarySchema } from './model.media-summary';
import { TechSummarySchema } from './model.tech-summary';

export const CertificationSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string().optional(),
  dateFrom: z.date(),
  dateTo: z.date().optional(),
  technologies: z.array(TechSummarySchema),
  issuingOrganization: z.string(),
  logo: MediaSummarySchema.optional(),
  url: z.string().optional()
});

export type Certification = z.infer<typeof CertificationSchema>;
