import { LanguagePublicResponse } from '@asgard/api-common';
import {
  LanguageLevel as LanguageLevelCommon,
  LanguagesResponse
} from '@asgard/common-dto';
import { ListItem } from 'interfaces/ui/ui-list-item';
import { Language, LanguageLevel } from 'model';

export const CommonDtoLevelToLanguageLevelDict: Record<
  keyof typeof LanguageLevelCommon,
  LanguageLevel
> = {
  Undefined: 'not-defined',
  Basic: 'basic',
  Medium: 'intermediate',
  Fluent: 'fluid',
  NativeBilingual: 'native'
};

export const LanguageLevelToCommonDtoLevelDict: Record<
  LanguageLevel,
  LanguageLevelCommon
> = {
  'not-defined': LanguageLevelCommon.Undefined,
  basic: LanguageLevelCommon.Basic,
  intermediate: LanguageLevelCommon.Medium,
  fluid: LanguageLevelCommon.Fluent,
  native: LanguageLevelCommon.NativeBilingual
};

// TODO: this LanguageResponse should be migrated to @asgard/api-common
export function normalizeToLanguages(
  response: LanguagesResponse | LanguagePublicResponse[]
): Language[] {
  return ('data' in response ? response.data : response).map((lang) => ({
    code: lang.code,
    id: lang.id,
    level: 'not-defined',
    name: lang.name
  }));
}

export function normalizeToLanguagesListItems(
  languages: Language[]
): ListItem<number>[] {
  return languages.map((lang) => ({
    label: lang.name || '',
    value: lang.id
  }));
}
