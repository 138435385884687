const family = {
  sans: 'Avenir, sans-serif',
  serif: "'Source Serif Pro', 'Times New Roman', serif",
  display: "'Big Shoulders Display', sans-serif",
  mono: "Menlo, Courier, monospace",
};

const size = {
  xs2: 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
  xl: 24,
  xl2: 28,
  xl3: 32,
  xl4: 40,
  xl5: 48,
  xl6: 56,
  xl7: 68,
  xl8: 84,
  xl9: 100,
  xl10: 120,
};

const weight = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

const typography = {
  size,
  family,
  weight,
};

export default typography;
