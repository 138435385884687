/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight, radius, spacing } from '../../theme';

export type NotificationVariant =
  | 'info'
  | 'loading'
  | 'notice'
  | 'positive'
  | 'negative'
  | 'stackOverflow'
  | 'linkedIn';

const typeVariants: Record<
  NotificationVariant,
  {
    backgroundColor: ((arg: any) => any) | string;
    color: ((arg: any) => any) | string;
    iconColor: ((arg: any) => any) | string;
  }
> = {
  info: {
    backgroundColor: color('info_200'),
    color: color('info_900'),
    iconColor: color('info_500')
  },
  loading: {
    backgroundColor: color('info_200'),
    color: color('info_900'),
    iconColor: color('info_500')
  },
  notice: {
    backgroundColor: color('notice_200'),
    color: color('notice_900'),
    iconColor: color('notice_700')
  },
  positive: {
    backgroundColor: color('positive_200'),
    color: color('positive_900'),
    iconColor: color('positive_500')
  },
  negative: {
    backgroundColor: color('negative_100'),
    color: color('negative_900'),
    iconColor: color('negative_500')
  },
  stackOverflow: {
    backgroundColor: color('notice_200'),
    color: color('notice_900'),
    iconColor: color('notice_700')
  },
  linkedIn: {
    backgroundColor: color('info_200'),
    color: color('info_900'),
    iconColor: color('info_700')
  }
};
export const StyledNotificationTitle = styled.div`
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('black')};
  line-height: 1.4;
`;

export const StyledNotificationIcon = styled.div`
  width: ${spacing('md')};
  height: ${spacing('md')};
  margin-top: 3px;
`;

export const StyledNotificationContent = styled.div`
  line-height: 1.75;
  li {
    list-style: circle inside;
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: ${fontWeight('black')};

    &:hover {
      text-decoration: none;
    }

    &:focus {
      opacity: 0.6;
    }
  }
`;

export const StyledNotificationAction = styled.a`
  margin-left: ${spacing('sm')};
  font-size: ${fontSize('md')};
`;

export const StyledNotification = styled.div<{
  variant: NotificationVariant;
}>`
  padding: ${spacing('md')};
  border-radius: ${radius('md')};
  font-size: ${fontSize('sm')};
  font-weight: ${fontWeight('medium')};
  display: flex;
  gap: ${spacing('xs')};
  min-width: 10.5rem;
  align-items: flex-start;

  ${({ variant }) =>
    variant &&
    typeVariants[variant] &&
    css`
      background-color: ${typeVariants[variant].backgroundColor};
      color: ${typeVariants[variant].color};

      ${StyledNotificationIcon} {
        color: ${typeVariants[variant].iconColor};
      }

      ${StyledNotificationAction} {
        color: ${typeVariants[variant].color};
      }
    `}

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;
