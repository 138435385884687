import { z } from 'zod';

// Schema.
// -----------------------------------------------------------------------------
export const LinkedInImportSchema = z.object({
  errorType: z.string().optional(),
  fieldsFilled: z.string().array().optional(),
  status: z.string(),
  step: z.string().optional()
});

export type LinkedInImport = z.infer<typeof LinkedInImportSchema>;
