import Notification from 'components/shared/Notification';
import { useEffect, useState } from 'react';
import {
  ToastProps,
  ToastProvider as ToastProviderLib
} from 'react-toast-notifications';
import { NotificationVariant } from '../../components/shared/Notification.styled';

const MyCustomToast: React.FC<
  ToastProps & { title?: string; loading?: boolean }
> = ({ appearance, children, transitionState, title, onDismiss, loading }) => {
  const [opacity, setOpacity] = useState(0);
  const variants: { [key: string]: string } = {
    success: 'positive',
    error: 'negative',
    warning: 'notice',
    info: 'info',
    loading: 'loading'
  };

  useEffect(() => {
    if (transitionState === 'entering' || transitionState === 'entered') {
      setOpacity(1);
    } else {
      setOpacity(0);
    }
  }, [transitionState]);

  return (
    <div
      style={{
        marginBottom: '1.5rem',
        transition: 'opacity 250ms ease-in',
        maxWidth: '30rem',
        opacity
      }}
    >
      <Notification
        variant={
          (loading ? 'loading' : variants[appearance]) as NotificationVariant
        }
        title={title}
        onClick={() => onDismiss()}
      >
        <span>{children}</span>
      </Notification>
    </div>
  );
};

const ToastProvider: React.FC = ({ children }) => {
  return (
    <ToastProviderLib
      components={{ Toast: MyCustomToast }}
      placement="bottom-right"
      autoDismiss={true}
    >
      {children}
    </ToastProviderLib>
  );
};
export default ToastProvider;
