import { useSessionStorage as useSessionStorageCommon } from '@asgard/ui-common';

// Types.
// -----------------------------------------------------------------------------
type UseSessionStorageReturnType<T> = [
  data: T | undefined,
  setValue: (value?: T | ((val?: T) => T)) => void
];

// Custom hook.
// -----------------------------------------------------------------------------
/**
 * @deprecated use from `@asgard/ui-common`
 * */
export const useSessionStorage = <T>(
  key: string,
  initialValue?: T
): UseSessionStorageReturnType<T> => {
  return useSessionStorageCommon(key, initialValue);
};
