import { OfferSummary } from '@asgard/common-dto';
import { Offer } from 'interfaces/offer.interface';
import { OffersListFilter } from 'model/model-offers-list-filter';
import * as fetch from 'utils/fetch';
import { getUrlParamsForAPICallFromJobOffersFilter } from 'utils/job-offers-filter';
import { config } from '../utils/config';

export enum OFFERS_STATUS {
  DRAFT = 'DRAFT',
  FINISHED = 'FINISHED',
  CLOSED = 'CLOSED',
  PAUSED = 'PAUSED',
  PUBLISHED = 'PUBLISHED',
  REACTIVATED = 'REACTIVATED',
  ACTIVE = 'ACTIVE'
}

export enum OFFER_TECH_SKILLS_SECTION {
  MUST = 'MUST',
  COULD = 'COULD',
  EXTRA = 'EXTRA'
}

export enum OFFER_SOFT_SKILLS_SECTION {
  MUST = 'MUST',
  COULD = 'COULD',
  EXTRA = 'EXTRA'
}

export enum OFFER_TECH_SKILLS_LEVEL {
  ADVANCED = 'ADVANCED',
  INTERMEDIATE = 'INTERMEDIATE',
  BASIC = 'BASIC'
}

export const OFFER_STATUS_CLOSED = [
  OFFERS_STATUS.FINISHED.toString(),
  OFFERS_STATUS.CLOSED.toString()
];

const baseUrl = `${config.apiUrl}/public/offers`;
const defaultListFilter: OffersListFilter = {
  onlyActive: true
};

/**
 * Get all locations from all the offers
 *
 * @returns array of location names
 */
export async function getOffersLocations(): Promise<string[]> {
  const res = await fetch.get<{
    data: string[];
  }>(`${baseUrl}/cities`);

  return res.data;
}

/**
 * Get a list of offers matching the given filter criteria
 *
 * @param filter - params for filtering the list of offers
 *
 * @returns a filtered list of offers
 */
export async function getOffersData(
  filter: OffersListFilter = defaultListFilter
): Promise<OfferSummary[]> {
  const queryParams = getUrlParamsForAPICallFromJobOffersFilter(filter);

  const res = await fetch.get<{
    data: OfferSummary[];
  }>(`${baseUrl}/?${queryParams}`);

  const { data } = res;

  if (!data) {
    return [];
  }

  return data;
}

/**
 * Get all the data for the offer with the given `offerId`
 *
 * @param offerId - ID of the offer
 *
 * @returns the offer info
 */
export async function getOfferData(offerId: string | number): Promise<Offer> {
  const res = await fetch.get<{ data: Offer }>(`${baseUrl}/${offerId}/`);

  return res.data;
}

/**
 * Get all the preview data for the offer with the given `offerId` and `previewId`
 *
 * @param offerId - ID of the offer
 * @param previewId - preview ID of the offer
 *
 * @returns the offer info
 */
export async function getOfferPreview(
  offerId: string,
  previewId: string
): Promise<Offer> {
  const res = await fetch.get<{
    data: Offer;
  }>(`${baseUrl}/${offerId}/preview/${previewId}`);

  return res.data;
}
