import { z } from 'zod';
import { LocationSchema } from './model.location';

export const WorkplacePreferencesSchema = z.object({
  isRemote: z.boolean(),
  isPartiallyRemote: z.boolean(),
  isFaceToFace: z.boolean(),
  isAnywhere: z.boolean(),
  desiredLocations: z.array(LocationSchema),
  comments: z.string().optional()
});

export type WorkplacePreferences = z.infer<typeof WorkplacePreferencesSchema>;
