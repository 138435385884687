module.exports = {
    apiUrl: {
        name: 'API_URL',
        defaultValue: "https://api.getmanfred.com"
    },

    appUrl: {
        name: 'AUTH0_BASE_URL'
    },

    auth0Audience: {
        name: 'AUTH0_AUDIENCE'
    },

    auth0Secret: {
        name: 'AUTH0_SECRET',
        hide: true
    },

    auth0ClientSecret: {
        name: 'AUTH0_CLIENT_SECRET',
        hide: true
    },
    
    auth0ClientId: {
        name: 'AUTH0_CLIENT_ID'
    },

    auth0IssuerBaseUrl: {
        name: 'AUTH0_ISSUER_BASE_URL'
    },

    disqusShortname: {
        name: 'NEXT_PUBLIC_DISQUS_SHORTNAME'
    },

    githubSyncInstallUrl: {
        name: 'NEXT_PUBLIC_GITHUB_SYNC_INSTALL_URL'
    },

    googlePlacesApiKey: {
        name: 'NEXT_PUBLIC_GOOGLE_PLACES_API_KEY'
    },

    gtmId: {
        name: 'NEXT_PUBLIC_GTM_ID'
    },

    segmentApiKey: {
        name: 'NEXT_PUBLIC_SEGMENT_API_KEY'
    },

    segmentCustomProxyUrl: {
        name: 'NEXT_PUBLIC_SEGMENT_CUSTOM_PROXY_URL'
    },

    segmentCustomCDN: {
        name: 'NEXT_PUBLIC_SEGMENT_CUSTOM_CDN'
    },

    shortUrl: {
        name: 'NEXT_PUBLIC_SHORT_URL'
    },

    wpUrl: {
        name: 'WP_URL',
        defaultValue: "https://wp.getmanfred.com"
    },

    pregenerateBlogPosts: {
        name: 'PREGEN_BLOG_POSTS',
        defaultValue: "0"
    },

    pregenerateOffers: {
        name: 'PREGEN_OFFERS',
        defaultValue: "0"
    },

    maxMediaSizeInKb: {
        name: 'MAX_MEDIA_SIZE_IN_KB',
        defaultValue: "2048"
    },

    defaultPerkIcon: {
        name: 'DEFAULT_PERK_ICON',
        defaultValue: '/images/icons/icon_default_perk.svg'
    },

    defaultTechIcon: {
        name: 'DEFAULT_TECH_ICON',
        defaultValue: '/images/icons/icon_default_tech.svg'
    },

    defaultSoftSkillIcon: {
        name: 'DEFAULT_SOFTSKILL_ICON',
        defaultValue: '/images/icons/icon_default_softskill.svg'
    },

    supportEmail: {
        name: 'SUPPORT_EMAIL',
        defaultValue: 'support@getmanfred.com'
    },

    flagsmithEnvironmentKey: {
        name: 'FLAGSMITH_ENVIRONMENT_KEY'
    },

    revalidationToken: {
        name: 'REVALIDATION_TOKEN',
        hide: true
    },
    cspHeaderValue: {
        name: 'CSP_HEADER_VALUE',
        defaultValue: "none",
        hide: true
    },
    cspHeaderName: {
        name: 'CSP_HEADER_NAME',
        defaultValue: "Content-Security-Policy-Report-Only",
        hide: true
    },
    stsHeaderValue: {
        name: 'STS_HEADER_VALUE',
        defaultValue: "none",
        hide: true
    },
    talentEngineClientUrl: {
        name: 'TALENT_ENGINE_CLIENT_URL'
    },
    talentEngineApiUrl: {
        name: 'TALENT_ENGINE_API_URL',
        defaultValue: "https://talent-engine.api.getmanfred.com"
    }
};
